import { AccountingPackageFromDatasourceNameId, AccountingPackage } from '../../Models/Api/AccountingPackages';
import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { CreateSubmissionRequest } from '../../Services/SubmissionRequestsService';
import { CreateSubmission } from '../../Services/SubmissionService';
import { ListWorkspaceConnections } from '../../Services/WorkspaceConnectionsService';

import { ErrorState } from '../ErrorBanner/ErrorBanner';
import { EventCategory, MetricsService } from '../../Utils/Metrics';
import { ImportMetadataTags } from '../../Utils/Constants';
import { getDelegatedToken, GetFinancialsImportOptions } from '../../Utils/LinkUtils';

import { LogException, LogMessage, SeverityLevel } from '../../Utils/Logging';

import {
    ImportFinancials,
    LenderConnectionOptions,
    StrongboxConnectionDescriptor,
    StrongboxConnectionRequest,
    StrongboxImportRequest,
} from '@finagraph/strongbox-finconnect-react';

import { ConnectionDescriptor } from '../../Models/Api/strongbox.financialportal';
import { ConnectionRequestDescriptor, ConsumerMetadata } from '../../Models/Api/strongbox.models';

import {
    ConnectionOptions,
} from '../../Store/ImportFinancials';

export async function CreateSubmissionForWorkspaceId(
    workspaceId: string,
    options: PortalCreateSubmissionRequestOptions,
    onSetError: (errorState: ErrorState) => void
): Promise<string> {
    try {
        LogMessage(
            `Creating a submission for workspace ${workspaceId}`,
            SeverityLevel.Information,
            {
                workspaceId,
                ...options,
            }
        );

        const subRequest = await CreateSubmissionRequest(
            workspaceId,
            options
        );

        if (!!subRequest) {
            LogMessage(
                `Created submission request for workspace ${workspaceId}, submission request id ${subRequest.id}`,
                SeverityLevel.Information,
                {
                    workspaceId
                }
            );
        }

        return await CreateSubmission(workspaceId, subRequest && subRequest.id);
    } catch (reason) {
        LogException(
            `Failed creating submission for ${workspaceId}`,
            reason,
            {
                workspaceId,
                ...options
            }
        );

        console.error('Failed creating submission for the workspace');
        console.error(reason);

        onSetError({
            summaryMessage: 'An error occurred linking customer financials.',
            extraInformation: (!!reason && !!reason.message) ? reason.message :
                'The existing connection may have expired or the owner\'s credentials may have changed.',
            severity: "Error"
        });

        throw reason;
    }
}

// Returns true if an asynchronous process to retrieve the delegated token was begun.
// 
// This means that we should go into the 'isWorking' state.  The processor for the 
// completion handler of the asynchronous event will clear the isWorkingState.

export async function RetrieveConnectionToken(
    accountingPackage: AccountingPackage,
    workspaceId: string,
    options: PortalCreateSubmissionRequestOptions,
    submissionId: string,
    strongboxUrl: string,
    onSetError: (errorState: ErrorState) => void,
    onSetWorking: (working: boolean) => void,
    onSetConnectionInfo: (ci: StrongboxConnectionDescriptor | undefined) => void,
    ignoreConnections: boolean,
): Promise<StrongboxConnectionDescriptor | undefined> {
    const errorMsg = 'A fatal error has occurred attempting to link financials.  Please try again later.';

    onSetWorking && onSetWorking(true);

    try {
        const token = await getDelegatedToken(workspaceId);

        let connection: ConnectionDescriptor | undefined = undefined;

        if (!ignoreConnections) {
            const connections = await ListWorkspaceConnections(workspaceId);
            connection = connections.connections.find(cxn => {
                return AccountingPackageFromDatasourceNameId(cxn.datasourceNameId) === accountingPackage;
            });

            if (!connection) {
                onSetError({
                    summaryMessage: 'The connection for this company has expired. Unable to import financials at this time.',
                    extraInformation: undefined,
                });
                onSetConnectionInfo(undefined);
                return;
            } 
        }
        
        let lenderOptions: LenderConnectionOptions | undefined = undefined;

        if (options) {
            lenderOptions = GetFinancialsImportOptions(options);
        }

        const connectionInfo: StrongboxConnectionDescriptor = {
            accountingPackage,
            delegatedAccessToken: token,
            strongboxUri: strongboxUrl,
            orgId: workspaceId,
            existingConnectionId: connection?.id,
            submissionId,
            initiator: 'portal',
            lenderManagedOptions: lenderOptions,
            sourceFlow: 'lender',
        }

        onSetConnectionInfo(connectionInfo);

        return connectionInfo;
    } catch (error) {
        onSetConnectionInfo(undefined);
        onSetError({
            summaryMessage: errorMsg,
            extraInformation: error.message,
        });

        return undefined;
    } finally {
        onSetWorking && onSetWorking(false);
    }
}

export function OnlineLinkFunc(
    pkg: AccountingPackage,
    id: string,
    options: PortalCreateSubmissionRequestOptions,
    submissionId: string,
    strongboxUrl: string,
    onSetError: (errorState: ErrorState) => void,
    onSetImporting: (working: boolean) => void,
    onSetConnectionInfo: (ci: StrongboxConnectionDescriptor | undefined) => void
): void {
    MetricsService.tryTrackEvent(EventCategory.Import, 'BeginWorkspaceList', { package: pkg });

    RetrieveConnectionToken(
        pkg,
        id,
        options,
        submissionId,
        strongboxUrl,
        onSetError,
        onSetImporting,
        onSetConnectionInfo,
        false
    );

    onSetConnectionInfo(undefined);
}

export async function ExecuteImport(
    connectionOptions: ConnectionOptions, 
    cxnRequest: StrongboxConnectionRequest, 
    apiRequestParameters?: ConnectionRequestDescriptor,
    onError?: (summaryMsg: string, detailedMsg: string, logProperties: any) => void,
    onImportStarted?: (financialRecordId: string, importRequest: StrongboxImportRequest) => void,
    saveImportParameters?: (workspaceId: string, accountingPackage: AccountingPackage, options: StrongboxImportRequest) => void,
    workspaceEngagementCode?: string,
    workspacePrimaryEmail?: string,
): Promise<boolean>  {
    // This should be filled in before getting here as there has to be a connection at this point.
    if (!cxnRequest.existingConnectionId) {
        return false;
    }

    const initialMetadata: ConsumerMetadata[] = [
        new ConsumerMetadata({
            label: ImportMetadataTags.sharedLinkCompanyName,
            value: connectionOptions.workspaceName,
        }),
        new ConsumerMetadata({
            label: ImportMetadataTags.sharedLinkApplicationId,
            value: workspaceEngagementCode || '',
        }),
        new ConsumerMetadata({
            label: ImportMetadataTags.borrowerBusinessName,
            value: connectionOptions.workspaceName,
        }),
        new ConsumerMetadata({
            label: ImportMetadataTags.borrowerBusinessEmail,
            value: workspacePrimaryEmail || '',
        })
    ]

    const importRequest: StrongboxImportRequest = {
        accountingPackage: cxnRequest.accountingPackage,
        lenderManagedOptions: GetFinancialsImportOptions(connectionOptions.options),
        initiator: 'portal',
        delegatedAccessToken: cxnRequest.delegatedAccessToken,
        orgId: cxnRequest.orgId,
        strongboxUri: cxnRequest.strongboxUri,
        submissionId: cxnRequest.submissionId,
        connectionId: cxnRequest.existingConnectionId,
        initialMetadata,
        sourceFlow: 'lender',
    }

    saveImportParameters && saveImportParameters(
        cxnRequest.orgId,
        cxnRequest.accountingPackage,
        importRequest
    );

    const logProperties = {
        workspaceId: cxnRequest.orgId,
        accountingPackage: cxnRequest.accountingPackage,
        lenderManagedOptions: {...importRequest.lenderManagedOptions},
        submissionId: cxnRequest.submissionId,
        connectionId: cxnRequest.existingConnectionId,
    };

    LogMessage(
        `ImportFinancials invoked`,
        SeverityLevel.Information,
        logProperties,
    );   

    await ImportFinancials(
        importRequest,
        (msg, detailedMsg) => {
            onError && onError(msg, detailedMsg, logProperties);
        },
        (financialRecordId: string, importRequest: StrongboxImportRequest) => {
            const logProperties = {
                workspaceId: importRequest.orgId,
                accountingPackage: importRequest.accountingPackage,
                lenderManagedOptions: {...importRequest.lenderManagedOptions},
                submissionId: importRequest.submissionId,
                connectionId: importRequest.connectionId,
                financialRecordId: financialRecordId,
            };
    
            LogMessage(
                `onImportStarted invoked`,
                SeverityLevel.Information,
                logProperties,
            );    
    
            MetricsService.tryTrackEvent(EventCategory.Import, 'JobCreatedWorkspaceList', { package: importRequest.accountingPackage });
    
            onImportStarted && onImportStarted(financialRecordId, importRequest);
        }
    )
    return true;
}
