import * as React from 'react';
import { Link, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Nav,
    NavItem,
    TabContent,
    TabPane
} from 'reactstrap';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';

import {
    GetFinancialStatementsEnabled,
    GetRiskCalcEnabled,
    GetDisableAdditionalDocUpload,
    GetDisableProvideuserCopy,
    GetDisableBasisOfAccounting,
    GetDisableAnonymizeResults,
} from '../../Store/AppSettings';
import {
    actionCreators as SubmissionHistoryActions,
    ActiveSubmissionDetails,
    GetActiveSubmissions,
    
} from '../../Store/SubmissionHistory';
import {
    actionCreators as FinancialStatementsActions,
    FinancialStatementWorkbookRegenerationTracking,
    GetWorkbookRegenerationTracking,
} from '../../Store/FinancialStatements'
import { GetMinMappingDate } from '../../Store/GlobalSettings';

import { ApplicationState } from '../../Store';
import { GetPortalBusyState } from '../../Store/UIState';
import { BusyWindowWrapper } from '../LoadingMessageWithIndicator/BusyWindowWrapper';

import {
    SubmissionDetail,
} from '../../Models/Api/strongbox.financialportal';
import { PortalSubmissionRequestFromSubmissionRequestSummary } from '../../Models/PortalCreateSubmissionRequestOptions';

import { breadCrumbContent, pathConstants, routeConstants, SubmissionDetailsTabNames } from '../../Utils/Constants';

import { SubmissionOverview } from './Overview/SubmissionOverview';
import { RiskAnalysis } from './RiskAnalysis/RiskAnalysis';

import { 
    DeleteSubmission,
    GetSubmissionDetail, 
    SearchSubmissions 
} from '../../Services/SubmissionService';

import { ErrorState, ErrorBanner } from '../ErrorBanner/ErrorBanner';

import { BuildQueryString } from '../../Utils/WorkspaceRouteInformation';

import { LogMessage, SeverityLevel } from '../../Utils/Logging';
import { ConnectionSourceText } from '../../Utils/Constants';
import { FormatDate, formatStringMDYFullMonth } from '../../Utils/DateUtils';
import { GetBasisOfAccountingDescription, GetCollectionRangeSummary } from '../../Utils/GlobalMessageUtils';

import { BreadCrumb, BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';
import { FinancialStatementEditor } from './FinancialStatements/FinancialStatementEditor';

import { InfoPopover, InfoPopoverItem } from '../InfoPopover/InfoPopover';

// 2 seconds
const updateRegenerationStatusTimeout = 2000;

type InjectedReduxState = {
    activeSubmissionDetails: ActiveSubmissionDetails[];
    financialStatementsEnabled: boolean;
    riskCalcEnabled: boolean;
    portalBusy: boolean;
    fileUploadDisabled: boolean;
    userCopyDisabled: boolean;
    anonymizeDisabled: boolean;
    basisOfAccountingDisabled: boolean;
    minValidDateForMapping?: Date;
    revisionTracking: FinancialStatementWorkbookRegenerationTracking[];
};

type InjectedActionCreators = typeof SubmissionHistoryActions & typeof FinancialStatementsActions;

type SubmissionDetailsProps = {
}

type Props = SubmissionDetailsProps & InjectedActionCreators & InjectedReduxState;

export const idSubmissionDetailsContainerWindow = 'submission-details-container';

const SubmissionDetailsComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        financialStatementsEnabled,
        activeSubmissionDetails,
        SetActiveSubmissionDetails,
        riskCalcEnabled,
        portalBusy,
        fileUploadDisabled,
        userCopyDisabled,
        ClearCompletedRevisionTracking,
        UpdateWorkbookRegenerationStatuses,
        revisionTracking,
        anonymizeDisabled,
        basisOfAccountingDisabled,
        minValidDateForMapping,
        SubmissionDeleted,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    // parameter name, i.e. 'submissionId' should match the value in routeConstants.submissionIdParam
    // workspaceId should match value in routeConstants.workspaceIdParam
    const {
        submissionId,
        workspaceId,
    } = useParams();

    const [activeSubmission, setActiveSubmission] = React.useState<ActiveSubmissionDetails | undefined>(undefined);
    const [matchedPath, setMatchedPath] = React.useState<string>('');
    const [rootPath, setRootPath] = React.useState<string>('/');

    const [working, setWorking] = React.useState<boolean>(false);

    const [returnCrumbs, setReturnCrumbs] = React.useState<BreadCrumb[]>([]);

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);

    const [revisionTrackingDismissed, setRevisionTrackingDismissed] = React.useState<boolean>(false);
    const [showSubmissionDetailsTargetId, setShowSubmissionDetailsTargetId] = React.useState<string | undefined>(undefined);

    const [showHighlightedRevision, setShowHighlightedRevision] = React.useState<boolean>(false);

    const highlightRevisionParameter = 'highlightrevision';

    const setAndClearRevisionTrackingDismissed = (dismissed: boolean): void => {
        if (dismissed) {
            ClearCompletedRevisionTracking();
        }
        setRevisionTrackingDismissed(dismissed);
    }

    const pathParameters = React.useMemo(() => {
        return `${!!workspaceId ? `/${escape(workspaceId)}` : ''}/${escape(submissionId || '')}`;
    }, [submissionId, workspaceId]);

    const loadActiveSubmission = async (): Promise<void> => {
        // Sanity check, shouldn't be possible
        if (!(workspaceId && submissionId)) {
            setWorking(false);
            return;
        }

        let submission: SubmissionDetail;

        try {
            submission = await GetSubmissionDetail(workspaceId, submissionId);
        } catch (exception) {
            setErrorState({
                severity: 'Error',
                summaryMessage: `Unable to retrieve details about this data collection`,
                extraInformation: `Please ensure that you have sufficient permission to view this data collection`,
            });
            setWorking(false);
            return;
        }

        const startDate = new Date(submission!.creationTime);
        const endDate = new Date(submission!.creationTime);

        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() + 1);

        const submissionList = await SearchSubmissions(startDate, endDate, workspaceId);
        const ssr = submissionList.find(sub => sub.submission.id === submissionId);

        if (!ssr) {
            // shouldn't be possible
            LogMessage(
                `Unexpected state in SubmissionDetails: Unable to retrieve submission search request from list of active requests, submissionId: ${submissionId}`,
                SeverityLevel.Warning,
                {
                    submissionId,
                    searchStart: startDate.toString(),
                    searchEnd: endDate.toString()
                }
            );
            setWorking(false);
            console.error('Unexpected state: SubmissionDetails unable to find submission search request for this page');
            navigate(pathConstants.home);
            return;
        }

        const activeSub: ActiveSubmissionDetails = {
            submissionSearchResult: ssr,
            submissionDetails: submission,
        }

        props.SetActiveSubmissionDetails(activeSub);

        setActiveSubmission(activeSub);

        setWorking(false);
    }

    React.useEffect(() => {
        if (!submissionId) {
            LogMessage(
                `Unexpected state in SubmissionDetails: submissionId is undefined, navigating to ${pathConstants.home}`,
                SeverityLevel.Warning,
            );
            console.error('Unexpected state: SubmissionDetails has no submission id in url, redirecting to submissions list');
            navigate(pathConstants.home);
        } else {
            const submission = activeSubmissionDetails.find(ad => ad.submissionSearchResult.submission.id === submissionId);
            if (!submission) {
                // Gotta have the workspaceId for this to work.
                if (!workspaceId) {
                    navigate(pathConstants.home);
                } else {
                    setWorking(true);
                    loadActiveSubmission();
                }
            } else {
                setActiveSubmission(submission);
            }
        }
        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateRevisionTracking = (): void => {
        UpdateWorkbookRegenerationStatuses(loadActiveSubmission)
    }

    React.useEffect(() => {
        if ((revisionTracking.length > 0) && (!revisionTrackingDismissed)) {
            const revisionStatusTimerId = setInterval(updateRevisionTracking, updateRegenerationStatusTimeout);

            return () => { clearInterval(revisionStatusTimerId) }
        }

        // This is in fact the dependency list I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revisionTracking, revisionTrackingDismissed])

    const submissionSupportsFinancialStatements = React.useMemo(() => {
        if (!(activeSubmission && activeSubmission.submissionDetails)) {
            return false;
        }
        if (!minValidDateForMapping) {
            return true;  // might as well go for it.  There are enough safeguards before this that we should have 
                          // confidence the setting is valid in the back end configuration
        }
        const submissionDate = new Date(activeSubmission.submissionDetails.creationTime);
        return submissionDate > minValidDateForMapping;

        // This is in fact the dependency list I want.  minValidDateForMapping won't actually change during a run
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSubmission]);

    const setActiveSubmissionDetails = (details: ActiveSubmissionDetails): void => {
        SetActiveSubmissionDetails(details);
        setActiveSubmission(details);
    }

    const navItemClass = (paths: string[]): string => {
        return (paths.findIndex(path => path === matchedPath) !== -1) ?
            `portal-nav-item-active` :
            `portal-nav-item-inactive`;
    }

    const navLinkClass = (paths: string[]): string => {
        return (paths.findIndex(path => path === matchedPath) !== -1) ? 'portal-nav-link-active' : ''
    }

    const navToLatestRevision = (): void => {
        navigate(`${routeConstants.workspaceSubmissionDetails}/overview/${workspaceId}/${submissionId}?${highlightRevisionParameter}=true`);
    }

    const getTabContent = (): React.ReactElement => {
        switch (matchedPath) {
            case pathConstants.workspaceSubmissionDetailsOverview:
            case pathConstants.allSubmissionDetailsOverview:
                return (
                    <TabPane key={'submissiondetailsoverviewtab'} tabId={routeConstants.submissionDetailsOverview}>
                        {activeSubmission && (
                            <BusyWindowWrapper
                                portalBusy={portalBusy}
                            >
                                <SubmissionOverview
                                    financialStatementsEnabled={financialStatementsEnabled}
                                    activeDetails={activeSubmission}
                                    setActiveSubmissionDetails={setActiveSubmissionDetails}
                                    augmentTitleWithLatestRevision={showHighlightedRevision}
                                    onDeleteSubmission={deleteSubmission}
                                />
                            </BusyWindowWrapper>
                        )}
                    </TabPane>
                );
            case pathConstants.workspaceSubmissionDetailsFinancialStatements:
            case pathConstants.allSubmissionDetailsFinancialStatements:
                return (
                    <TabPane key={'submissiondetailsbalancesheettab'} tabId={routeConstants.submissionDetailsFinancialStatements}>
                        {activeSubmission && (
                            <BusyWindowWrapper
                                portalBusy={portalBusy}
                            >
                                <FinancialStatementEditor
                                    financialRecordId={activeSubmission.submissionDetails.financialRecordId || ''}
                                    orgId={activeSubmission.submissionDetails.entityId}
                                    revisionTrackingDismissed={revisionTrackingDismissed}
                                    setRevisionTrackingDismissed={setAndClearRevisionTrackingDismissed}
                                    revisionTracking={revisionTracking}
                                    submissionSupportsFinancialStatements={submissionSupportsFinancialStatements}
                                    onNavToLatestRevision={navToLatestRevision}
                                />
                            </BusyWindowWrapper>
                        )}
                    </TabPane>
                );
            case pathConstants.workspaceSubmissionDetailsRiskAnalysis:
            case pathConstants.allSubmissionDetailsRiskAnalysis:
                // Shouldn't be possible to get here if there's no financial record ID, i.e. we shouldn't be showing the tab
                // if it isn't part of the activeSubmission.
                return (
                    <TabPane key={'businesssubmissiondetailsriskanalysistab'} tabId={routeConstants.submissionDetailsRiskAnalysis}>
                        {activeSubmission && !!activeSubmission.submissionSearchResult.submission.financialRecordId && !working && (
                            <BusyWindowWrapper
                                portalBusy={portalBusy}
                            >
                                <RiskAnalysis
                                    businessName={activeSubmission?.submissionSearchResult.entity.displayName || ''}
                                    entityId={workspaceId || ''}
                                    financialRecordId={activeSubmission!.submissionSearchResult.submission.financialRecordId}
                                />
                            </BusyWindowWrapper>
                        )}
                    </TabPane>
                )
        }
        return (<></>);
    }

    React.useEffect(() => {
        let workspaceReturnLink = '';
        let allReturnLink = pathConstants.dataCollectionReport;
        let workspaceDisplayName = '';

        if (!!activeSubmission) {
            const workspaceId = activeSubmission.submissionSearchResult.entity.id;
            const workspaceName = activeSubmission.submissionSearchResult.entity.displayName;

            if (!!workspaceName) {
                workspaceDisplayName = workspaceName;
            }

            workspaceReturnLink = `${pathConstants.workspaceDetailsSubmissionList}/${BuildQueryString({ workspaceId: workspaceId, workspaceName: workspaceName || '' })}`;
        }

        switch (matchedPath) {
            case pathConstants.workspaceSubmissionDetailsOverview:
            case pathConstants.workspaceSubmissionDetailsFinancialStatements:
            case pathConstants.workspaceSubmissionDetailsRiskAnalysis:
                setReturnCrumbs([
                    {
                        content: breadCrumbContent.workspaceList,
                        link: pathConstants.home
                    },
                    {
                        content: workspaceDisplayName,
                        link: workspaceReturnLink
                    }
                ]);
                break;
            default:
                setReturnCrumbs([
                    {
                        content: breadCrumbContent.workspaceList,
                        link: pathConstants.home
                    },
                    {
                        content: 'Data Collection Report',
                        link: allReturnLink
                    }
                ]);
                break;
        }
    }, [activeSubmission, matchedPath])

    const activeTab: SubmissionDetailsTabNames | undefined = React.useMemo(() => {
        // submissionId will not be undefined here.
        const pathToMatch = location.pathname.replace(`${!!workspaceId ? `/${escape(workspaceId)}` : ''}/${escape(submissionId || '')}`, '');
        setMatchedPath(pathToMatch);

        let result: SubmissionDetailsTabNames;

        switch (pathToMatch) {
            case pathConstants.workspaceSubmissionDetailsOverview:
            case pathConstants.allSubmissionDetailsOverview:
                result = 'overview';
                break;
            case pathConstants.workspaceSubmissionDetailsFinancialStatements:
            case pathConstants.allSubmissionDetailsFinancialStatements:
                result = 'financialstatements';
                break;
            case pathConstants.workspaceSubmissionDetailsRiskAnalysis:
            case pathConstants.allSubmissionDetailsRiskAnalysis:
                result = 'riskanalysis';
                break;
            default:
                setRootPath('/');
                return undefined;
        }

        setRootPath(pathToMatch.replace(`/${result}`, ''));

        if (!!location.search) {
            const searchParams = new URLSearchParams(location.search);
            const highlightRevision = searchParams.get(highlightRevisionParameter);
            if (highlightRevision !== null) {
                setShowHighlightedRevision(highlightRevision === 'true');
            } else {
                setShowHighlightedRevision(false);
            }
        } else {
            setShowHighlightedRevision(false);
        }

        return result;
    }, [location.pathname, location.search, workspaceId, submissionId])

    const collectionRangeSummary = React.useMemo(() => {
        let result = '';

        if (!!(activeSubmission?.submissionSearchResult.submission?.submissionRequest)) {
            result = GetCollectionRangeSummary(
                PortalSubmissionRequestFromSubmissionRequestSummary(activeSubmission.submissionSearchResult.submission.submissionRequest)
            );
        }
        return result;
    }, [activeSubmission]);

    const basisOfAccounting = React.useMemo(() => {
        let result = '';

        if (!!(activeSubmission?.submissionSearchResult.submission?.submissionRequest)) {
            result = GetBasisOfAccountingDescription(
                PortalSubmissionRequestFromSubmissionRequestSummary(activeSubmission.submissionSearchResult.submission.submissionRequest)
            );
        }
        return result;
    }, [activeSubmission]);

    const toggledDetailItems: InfoPopoverItem[] = React.useMemo(() => {
        const result: InfoPopoverItem[] = [];

        if (!anonymizeDisabled) {
            result.push({
                title: 'Customer and vendor names are anonymized',
                value: activeSubmission?.submissionSearchResult.submissionRequest?.anonymizeCustomersAndVendors ? 'Yes' : 'No'
            });
        }

        if (!!(activeSubmission?.submissionSearchResult.submissionRequest?.hasShareableLink)) {
            if (!fileUploadDisabled) {
                result.push({
                    title: 'Use may upload additional files',
                    value: activeSubmission.submissionSearchResult.submissionRequest.allowUserUpload ? 'Yes' : 'No'
                })
            }
            if (!userCopyDisabled) {
                result.push({
                    title: 'User may download generated workbooks',
                    value: activeSubmission.submissionSearchResult.submissionRequest.provideUserCopy ? 'Yes' : 'No'
                })
            }
        }
        return result;

        // activeSubmission is the only dependency I really care about
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSubmission]);

    if (activeTab === undefined) {
        return (<Navigate to={pathConstants.notFound} />)
    }

    const getRowContent = (): InfoPopoverItem[] => {
        const nonToggled: InfoPopoverItem[] = [
            {
                title: 'Collected on',
                value: !!(activeSubmission?.submissionDetails.creationTime) ?
                    FormatDate(new Date(activeSubmission.submissionDetails.creationTime), formatStringMDYFullMonth) : ''
            },
            {
                title: 'Originating from',
                value: activeSubmission?.submissionSearchResult.submissionRequest?.hasShareableLink ?
                    ConnectionSourceText.SecureUrlText :
                    ConnectionSourceText.DirectConnectionText
            },
            {
                title: 'Requested by',
                value: activeSubmission?.submissionSearchResult.requestingUser?.displayName || ''
            },
            {
                title: 'Collection period',
                value: collectionRangeSummary
            }
        ];
        if (!basisOfAccountingDisabled) {
            nonToggled.push(
                {
                    title: 'Preferred basis of accounting',
                    value: basisOfAccounting
                }
            );
        }
        return nonToggled.concat(toggledDetailItems);
    }

    const deleteSubmission = (submissionId: string): void => {
        DeleteSubmission(submissionId)
            .then(() => {
                if (!!workspaceId) {
                    SubmissionDeleted(workspaceId, submissionId);
                    navigate(`${pathConstants.workspaceDetailsSubmissionList}/${workspaceId}`);
                }
            })
            .catch(() => {
                setErrorState({
                    severity: 'Error',
                    summaryMessage: `There was an error deleting the submission. The submission has not been deleted`,
                });
            })
    }

    return (
        <div
            id={idSubmissionDetailsContainerWindow}
            className={`free-content-region-wide`}
        >
            <div style={{ height: '100%' }} className={`control-region control-region-lender`} >
                {
                    working && (
                        <LoadingIndicator
                            style={{
                                position: 'fixed',
                                top: '45%'
                            }}
                            active={true}
                        />
                    )
                }
                {
                    errorState && (
                        <ErrorBanner
                            errorState={{...errorState, actions:[
                                {
                                    text: 'Dismiss',
                                    id: 'dismissAction',
                                    onAction: (actionId: string) => setErrorState(undefined)
                                }
                            ]}}
                            onDefaultActionButton={() => setErrorState(undefined)}
                        />
                    )
                }
                {
                    showSubmissionDetailsTargetId && (
                        <InfoPopover
                            target={showSubmissionDetailsTargetId}
                            onToggle={(on) => !on && setShowSubmissionDetailsTargetId(undefined)}
                            rows={getRowContent()}
                            title={'Submission Details'}
                            placement={'bottom-end'}
                        />
                    )
                }
                <div className={'spaced-row free-content-wide-content'}>
                    <BreadCrumbs
                        crumbs={returnCrumbs}
                        showInformation={{
                            active: true,
                            tipTitle: 'Submission Details',
                            cancelTip: !!showSubmissionDetailsTargetId,
                            onInfoClick: (idTarget) => setShowSubmissionDetailsTargetId(idTarget)
                        }}
                    />
                    <Nav
                        tabs
                        className={`portal-tabs tabs-right`}
                    >
                        <NavItem
                            key={'nav-submissiondetailsoverview'}
                            className={`portal-nav-item ${navItemClass([pathConstants.workspaceSubmissionDetailsOverview, pathConstants.allSubmissionDetailsOverview])}`}
                        >
                            <Link
                                to={`${rootPath}/${routeConstants.submissionDetailsOverview}${pathParameters}`}
                                className={`portal-nav-link ${navLinkClass([pathConstants.workspaceSubmissionDetailsOverview, pathConstants.allSubmissionDetailsOverview])}`}
                            >
                                Overview
                            </Link>
                        </NavItem>
                        {
                            props.financialStatementsEnabled && (
                                <>
                                    <NavItem
                                        key={'nav-financial-statements'}
                                        className={`portal-nav-item ${navItemClass([pathConstants.workspaceSubmissionDetailsFinancialStatements, pathConstants.allSubmissionDetailsFinancialStatements])}`}
                                    >
                                        <Link
                                            className={`portal-nav-link ${navLinkClass([pathConstants.workspaceSubmissionDetailsFinancialStatements, pathConstants.allSubmissionDetailsFinancialStatements])}`}
                                            to={`${rootPath}/${routeConstants.submissionDetailsFinancialStatements}${pathParameters}`}
                                        >
                                            Financial Statements
                                        </Link>
                                    </NavItem>
                                </>
                            )
                        }
                        {
                            riskCalcEnabled && !!activeSubmission && !!activeSubmission.submissionSearchResult.submission.financialRecordId && (
                                <NavItem
                                    key={'nav-submissiondetailsriskanalysis'}
                                    className={`portal-nav-item ${navItemClass([pathConstants.workspaceSubmissionDetailsRiskAnalysis, pathConstants.allSubmissionDetailsRiskAnalysis])}`}
                                >
                                    <Link
                                        className={`portal-nav-link ${navLinkClass([pathConstants.workspaceSubmissionDetailsRiskAnalysis, pathConstants.allSubmissionDetailsRiskAnalysis])}`}
                                        to={`${rootPath}/${routeConstants.submissionDetailsRiskAnalysis}${pathParameters}`}
                                    >
                                        Risk analysis
                                    </Link>
                                </NavItem>
                            )
                        }
                    </Nav>
                </div>
                <TabContent
                    activeTab={activeTab}
                    className={'portal-tab-content portal-tab-content-normal'}
                >
                    {getTabContent()}
                </TabContent>
            </div>
        </div>
    );
}

export const SubmissionDetails = connect<InjectedReduxState, InjectedActionCreators, SubmissionDetailsProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            activeSubmissionDetails: GetActiveSubmissions(appState),
            financialStatementsEnabled: GetFinancialStatementsEnabled(appState),
            riskCalcEnabled: GetRiskCalcEnabled(appState),
            portalBusy: GetPortalBusyState(appState),
            fileUploadDisabled: GetDisableAdditionalDocUpload(appState),
            userCopyDisabled: GetDisableProvideuserCopy(appState),
            revisionTracking: GetWorkbookRegenerationTracking(appState),
            anonymizeDisabled: GetDisableAnonymizeResults(appState),
            basisOfAccountingDisabled: GetDisableBasisOfAccounting(appState),
            minValidDateForMapping: GetMinMappingDate(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...SubmissionHistoryActions,
            ...FinancialStatementsActions
        },
        dispatch
    )
)(SubmissionDetailsComponent);
