import { ActiveSubmissionDetails, DownloadableFileState, SubmissionSearchResultWithDate, SubmissionHistoryType } from './Reducer';
import {
    ISetActiveSubmission,
    ISetCurrentHistoryRange,
    ISetCurrentRangeType,
    ISetCurrentResults,
    ISetCurrentScrollPos,
    ISetSortColumn,
    ISetSortOrder,
    ISetStatusFilter,
    ISetSourceFilter,
    ITempSubmissionDetailsStateClear,
    ITempSubmissionDetailsStateSetSelectedFiles,
    ITempSubmissionDetailsStateToggleSelectedFile,
    IUpdateWorkspaceName,
    SubmissionDetailsActions,
    ISubmissionDeleted,
    ISubmissionRestored,
} from './Actions';

import { SubmissionHistoryRange, SubmissionHistoryRangeSelectionType } from '../../Utils/SubmissionHistoryRange';


function SetActiveSubmissionDetails(details?: ActiveSubmissionDetails): ISetActiveSubmission {
    return {
        type: SubmissionDetailsActions.SetActiveSubmission,
        submissionDetails: details,
    }
}

function SetCurrentScrollPos(currentScrollPos: number): ISetCurrentScrollPos {
    return {
        type: SubmissionDetailsActions.SetCurrentScrollPos,
        scrollPos: currentScrollPos,
    }
}

function SetCurrentResults(results: SubmissionSearchResultWithDate[], workspaceId?: string): ISetCurrentResults {
    return {
        type: SubmissionDetailsActions.SetCurrentResults,
        results,
        workspaceId,
    }
}

function SetCurrentHistoryRange(range: SubmissionHistoryRange, historyType: SubmissionHistoryType): ISetCurrentHistoryRange {
    return {
        type: SubmissionDetailsActions.SetCurrentHistoryRange,
        currentRange: range,
        historyType
    }
}

function SetCurrentRangeSelectionType(rangeType: SubmissionHistoryRangeSelectionType, historyType: SubmissionHistoryType): ISetCurrentRangeType {
    return {
        type: SubmissionDetailsActions.SetCurrentRangeType,
        rangeType,
        historyType
    }
}

function SetSortColumn(column: number): ISetSortColumn {
    return {
        type: SubmissionDetailsActions.SetSortColumn,
        column,
    }
}

function SetSortOrder(sortOrder: string): ISetSortOrder {
    return {
        type: SubmissionDetailsActions.SetSortOrder,
        order: sortOrder,
    }
}

function SetStatusFilter(id: string, state: boolean): ISetStatusFilter {
    return {
        type: SubmissionDetailsActions.SetStatusFilter,
        id,
        state
    };
}

function SetSourceFilter(id: string, state: boolean): ISetSourceFilter {
    return {
        type: SubmissionDetailsActions.SetSourceFilter,
        id,
        state
    };
}

function UpdateWorkspaceName(id: string, newName: string): IUpdateWorkspaceName {
    return {
        type: SubmissionDetailsActions.UpdateWorkspaceNameInResults,
        id,
        newName
    };
}

function ClearTempSubmissionDetails(): ITempSubmissionDetailsStateClear {
    return {
        type: SubmissionDetailsActions.TempSubmissionDetailsStateClear
    };
}

function SetTempSubmissionDetailsFiles(submissionId: string, files: DownloadableFileState[], preserveSelectionState?: boolean): ITempSubmissionDetailsStateSetSelectedFiles {
    return {
        type: SubmissionDetailsActions.TempSubmissionDetailsStateSetSelectedFiles,
        submissionId,
        files,
        preserveSelectionState,
    };
}

function ToggleSubmissionDetailsSelectedFile(submissionId: string, fileId: string): ITempSubmissionDetailsStateToggleSelectedFile {
    return {
        type: SubmissionDetailsActions.TempSubmissionDetailsStateToggleSelectedFile,
        submissionId,
        fileId,
    };
}

function SubmissionDeleted(workspaceId: string, submissionId: string): ISubmissionDeleted {
    return {
        type: SubmissionDetailsActions.SubmissionDeleted,
        workspaceId,
        submissionId,
    };
}

function SubmissionRestored(workspaceId: string, submissionId: string): ISubmissionRestored {
    return {
        type: SubmissionDetailsActions.SubmissionRestored,
        workspaceId,
        submissionId,
    };
}

export const actionCreators = {
    ClearTempSubmissionDetails: ClearTempSubmissionDetails,
    SetTempSubmissionDetailsFiles: SetTempSubmissionDetailsFiles,
    SetActiveSubmissionDetails: SetActiveSubmissionDetails,
    SetCurrentScrollPos: SetCurrentScrollPos,
    SetCurrentResults: SetCurrentResults,
    SetCurrentHistoryRange: SetCurrentHistoryRange,
    SetCurrentRangeSelectionType: SetCurrentRangeSelectionType,
    SetSortColumn: SetSortColumn,
    SetSortOrder: SetSortOrder,
    SetStatusFilter: SetStatusFilter,
    SetSourceFilter: SetSourceFilter,
    ToggleSubmissionDetailsSelectedFile: ToggleSubmissionDetailsSelectedFile,
    UpdateWorkspaceName: UpdateWorkspaceName,
    SubmissionDeleted: SubmissionDeleted,
    SubmissionRestored: SubmissionRestored,
};
