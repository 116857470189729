import { LogMessage, SeverityLevel } from '../../Utils/Logging';

export enum AccountingPackage {
    MYOBBusiness = "MYOBBusiness",
    BusinessCentral = "BusinessCentral",
    Example = "Example",
    FreeAgent = "FreeAgent",
    FreshBooks = "FreshBooks",
    NetSuite = "NetSuite",
    QuickBooksDesktop = "QuickBooksDesktop",
    QuickBooksOnline = "QuickBooksOnline",
    SageIntacct = "SageIntacct",
    SageOne = "SageOne",
    Xero = "Xero",
    FileUpload = "FileUpload",
}

export function AccountingPackageFromDatasourceNameId(datasourceNameId: string): AccountingPackage | undefined {
    switch (datasourceNameId.toLowerCase()) {
        case 'quickbooksonline':
            return AccountingPackage.QuickBooksOnline;
        case 'quickbooksdesktop':
            return AccountingPackage.QuickBooksDesktop;
        case 'xero':
            return AccountingPackage.Xero;
        case 'sageintacct':
            return AccountingPackage.SageIntacct;
        case 'example':
            return AccountingPackage.Example;
        case 'freeagent':
            return AccountingPackage.FreeAgent;
        case 'netsuite':
            return AccountingPackage.NetSuite;
        case 'businesscentral':
            return AccountingPackage.BusinessCentral;
        case 'myobbusiness':
            return AccountingPackage.MYOBBusiness;
        case 'fileupload':
            return AccountingPackage.FileUpload;
           
        default:
            LogMessage(
                `Unable to convert datasourceNameId to AccountingPackage ${datasourceNameId}`,
                SeverityLevel.Error,
                {
                    datasourceNameId
                }
            );
            return undefined;
    }
}

export function DatasourceMayDirectLink(datasourceNameId: string): boolean {
    switch (datasourceNameId.toLowerCase()) {
        case 'freeagent':
        case 'quickbooksonline':
        case 'xero':
        case 'sageintacct':
        case 'example':
        case 'netsuite':
        case 'businesscentral':
        case 'myobbusiness': // technically it depends on if the file is hosted in the cloud or not, but this works for the short-term
        case 'fileupload':
            return true;
        default:
            return false;
    }
}
