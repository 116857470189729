import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { 
    CompareCreationTime,
    CompareStringsDefaultToCreationTime,
    GetSubmissionStatusIconColumn, 
    GetSubmissionStatus,
    GetWorkspaceDisplayName,
} from '../ComponentUtils/SubmissionUtils';
import { BrandConfig, GetBrandConfig } from '../../Store/Tenant';
import { LinkMode } from '../../Store/ImportFinancials';
import { GetLoggedInUser, GetUsers } from '../../Store/User';
import { FormatDate, formatStringMDYFullMonth } from '../../Utils/DateUtils';
import { DeleteSubmission } from '../../Services/SubmissionService';
import { GetStdThemeColors } from '../../Utils/Style';
import {
    actionCreators as WorkspacesActions,
    LoadWorkspaceDetails,
} from '../../Store/Workspaces';
import { actionCreators as SubmissionHistoryActions } from '../../Store/SubmissionHistory';
import { ApplicationState } from '../../Store';
import {
    actionCreators as UIStateActions,
    GetPortalBusyState,
} from '../../Store/UIState';
import { ErrorState, ErrorBanner } from '../ErrorBanner/ErrorBanner';

import { FinancialsRelinkButton } from '../FinancialsImport/FinancialsRelinkButton';
import { ConfirmModal, ConfirmModalType } from '../ConfirmModal/ConfirmModal';

import { 
    SubmissionSearchResult,
    UserResponse,
} from '../../Models/Api/strongbox.financialportal';

import {
    Col,
    Container,
    Row,
} from 'reactstrap';

import InformationIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
    SortableTable, 
    SortableTableColumn, 
    SortableTableSortInformation,
} from '../SortableTable/SortableTable';

import {
    Tooltip
} from '@mui/material';

import UsersIcon from '@mui/icons-material/GroupAdd';

type InjectedReduxState = {
    brandConfig: BrandConfig;
    portalBusy: boolean;
    allUsers: UserResponse[];   // needed when the user is an admin and wants to manage access to a workspace
    loggedInUser?: UserResponse;
};

type InjectedActionCreators = 
    typeof WorkspacesActions & 
    typeof UIStateActions & 
    typeof SubmissionHistoryActions;

type SubmissionsPendingLenderInterventionProps = {
    submissions: SubmissionSearchResult[];
    onSubmissionDeleted?: (submission: SubmissionSearchResult) => void;
};

type Props = SubmissionsPendingLenderInterventionProps & InjectedReduxState & InjectedActionCreators;

export const SubmissionsPendingLenderInterventionComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        allUsers,
        brandConfig,
        submissions,
        portalBusy,
        SetPortalWorking,
        SetPortalIdle,
        SetModifyWorkspaceUsersState,
        SubmissionDeleted,
        onSubmissionDeleted,
    } = props;

    const [sortState, setSortState] = React.useState<SortableTableSortInformation>({
        sortColumn: 1,           // Creation time column
        sortType: 'ascending',
    });
    const [confirmDelete, setConfirmDelete] = React.useState<SubmissionSearchResult | undefined>(undefined);

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);

    const getLinkContent = (submission: SubmissionSearchResult): React.ReactElement => {
        return (
            <FinancialsRelinkButton
                datasourceNameId={submission.submission.datasourceNameId}
                disabledByProp={portalBusy}
                onSetErrorState={setErrorState}
                onSetImporting={(working) => {
                    const workingId = `pendingInterventionsImporting-${submission.submission.id}`;
                    if (working) {
                        SetPortalWorking(workingId);
                    } else {
                        SetPortalIdle(workingId);
                    }
                }}
                workspaceId={submission.entity.id}
                workspaceDisplayName={submission.entity.displayName || ''}
                buttonType={'small-button'}
                submissionId={submission.submission.id}
                submissionRequestId={submission.submission.submissionRequest?.id || 0}
                linkImmediate={true}
            />
        );
    }
    
    // We may or may not have this workspace metadata loaded at this point depending on what is actually displayed in the
    // workspace list. For purposes of how often this path to request access will be used, just load the ws details
    // from the server.

    const requestWorkspaceAccess = async (submission: SubmissionSearchResult): Promise<void> => {
        const workingMsg = 'SubmissionsPendingIntervention:RequestAccess';

        // To get to this point, the user will (under current circumstances) be an admin and will have permission
        // to retrieve entity details.

        SetPortalWorking(workingMsg);
        try {
            const wsDetails = await LoadWorkspaceDetails(submission.entity.id, allUsers);
            if (!wsDetails) {
                setErrorState({
                    summaryMessage: 'Unable to retrieve workspace information to allow for editing user access.',
                });
                return;
            }
            if (!!wsDetails.entityDetails.workspaceUserCapabilities) {
                var userCanEdit = wsDetails.entityDetails.workspaceUserCapabilities.find(cap => cap === 'CanManageWorkspaceUsers');
                if (!!userCanEdit) {
                    SetModifyWorkspaceUsersState({
                        workspace: wsDetails.entityDetails,
                        users: wsDetails.userInformation.users || [],
                    });
                }
            }
        } finally {
            SetPortalIdle(workingMsg);
        }
    }

    const getRequestWorkspaceAccess = (submission: SubmissionSearchResult): React.ReactElement => {
        // Currently, there's no need to have a request access button.  For non-admins, the only submissions they'll get are those belonging to workspaces
        // to which they already have access.

        return (
            <Tooltip title={'You do not currently have access to the containing workspace, however, you may add yourself.'}>
                <button
                    type="button"
                    onClick={() => { requestWorkspaceAccess(submission) }}
                    disabled={portalBusy}
                    className={'job-icon shadowed-accounting-button'}
                >
                    <UsersIcon style={{ margin: 'auto' }} />
                </button>
            </Tooltip>
        )
    }

    const getActionColumn = (submission: SubmissionSearchResult): React.ReactElement => {
        const stdColors = GetStdThemeColors(brandConfig);

        return (
            <div 
                className={'table-icon-column'}
                style={{
                    paddingLeft: `${!submission.entity.accessible ? '3' : '0'}px`  // adjustment for extra width of financials relink button on other lines
                }}
            >
                { submission.entity.accessible && (
                    <React.Fragment>
                        { getLinkContent(submission) }
                        <Tooltip
                    key={`delete-incomplete-connection-tip-${submission.submission.id}`}
                    title={'Delete data collection'}
                >
                    <button
                        key={`delete-incomplete-connection-${submission.submission.id}`}
                        type="button"
                        className={'shadowed-accounting-button secondary-button'}
                        onClick={() => setConfirmDelete(submission)}
                        disabled={portalBusy}
                    >
                        <DeleteIcon style={{ backgroundColor: stdColors.secondaryColor, margin: 'auto' }} />
                    </button>
                </Tooltip>
                    </React.Fragment>
                )}

                { !submission.entity.accessible && getRequestWorkspaceAccess(submission)}
            </div>
        );
    }

    const tableColumns = React.useMemo(() => {
        const fullTableColumns: SortableTableColumn<SubmissionSearchResult>[] = [];

        fullTableColumns.push({
            defaultSortOrder: 'none',
            headerTitle: '',
            compare: ((ssr1: SubmissionSearchResult, ssr2: SubmissionSearchResult) => {
                return 0;
            }),
            getRowData: (rowData: SubmissionSearchResult) => {
                if (!rowData.submission) {
                    return (<></>);
                }
                return GetSubmissionStatusIconColumn(
                    brandConfig,
                    LinkMode.shareable,
                    rowData.submission.id,
                    rowData.submission.financialRecordStatus,
                    rowData.submission.financialRecordId,
                    rowData.submission.financialRecordCreated

                )
            }
        });
        fullTableColumns.push({
            defaultSortOrder: 'descending',
            headerTitle: 'Created',
            compare: (s1, s2) => {
                const d1: Date | undefined = !!s1.submission?.creationTime ? new Date(s1.submission.creationTime) : undefined;
                const d2: Date | undefined = !!s2.submission?.creationTime ? new Date(s2.submission.creationTime) : undefined;

                return CompareCreationTime(d1, d2) ;
            },
            getRowData: (rowData: SubmissionSearchResult) => {
                GetSubmissionStatus(
                    rowData.submission?.financialRecordStatus,
                    rowData.submission?.financialRecordId,
                    rowData.submission?.creationTime
                );

                return (
                    <span>
                        {!!rowData.submission?.creationTime ? FormatDate(new Date(rowData.submission.creationTime), formatStringMDYFullMonth) : ''}
                    </span>
                )
            }
        });
        fullTableColumns.push({
            defaultSortOrder: 'ascending',
            headerTitle: 'Workspace name',
            compare: ((s1, s2) => CompareStringsDefaultToCreationTime(
                s1.entity.displayName,
                s2.entity.displayName,
                !!s1.submission?.creationTime ? new Date(s1.submission?.creationTime) : undefined,
                !!s2.submission?.creationTime ? new Date(s2.submission?.creationTime) : undefined
            )),
            getRowData: (rowData: SubmissionSearchResult) => {
                return (
                    <>{GetWorkspaceDisplayName(false, rowData.entity.displayName)}</>
                );
            }
        })

        fullTableColumns.push({
            defaultSortOrder: 'ascending',
            headerTitle: 'Requested by (email)',
            compare: ((s1: SubmissionSearchResult, s2: SubmissionSearchResult) => {
                const ru1 = (s1.requestingUser?.emailAddress && s1.requestingUser.emailAddress.toLowerCase()) || '';
                const ru2 = (s2.requestingUser?.emailAddress && s2.requestingUser.emailAddress.toLowerCase()) || '';

                if (ru1 === ru2) {
                    return CompareStringsDefaultToCreationTime(
                        s1.entity.displayName,
                        s2.entity.displayName,
                        !!s1.submission?.creationTime ? new Date(s1.submission?.creationTime) : undefined,
                        !!s2.submission?.creationTime ? new Date(s2.submission?.creationTime) : undefined
                    );
                }
                return (ru1 < ru2) ? -1 : 1;
            }),
            getRowData: (rowData: SubmissionSearchResult) => {
                return (
                    <>{(rowData.requestingUser?.emailAddress && rowData.requestingUser.emailAddress) || ''}</>
                );
            }
        });

        fullTableColumns.push({
            defaultSortOrder: 'none',
            headerTitle: 'Actions',
            compare: ((ssr1: SubmissionSearchResult, ssr2: SubmissionSearchResult) => {
                return 0;
            }),
            getRowData: (rowData: SubmissionSearchResult) => {
                return getActionColumn(rowData);
            }
        })

        return fullTableColumns;

        // I do not want to include the functions in the dependency array.  That would cause the memoization to be changed
        // on just about every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissions, portalBusy])

    const deleteSubmission = (submission: SubmissionSearchResult): void => {
        DeleteSubmission(submission.submission.id)
            .then(() => {
                SubmissionDeleted(submission.entity.id, submission.submission.id);
                onSubmissionDeleted && onSubmissionDeleted(submission);
            })
            .catch(() => {
                setErrorState({
                    severity: 'Error',
                    summaryMessage: `There was an error deleting the submission. The submission has not been deleted`,
                });
            })
    }

    return (
        <div>
            <Container
                fluid
            >
                <Row>
                    <Col className={'icon-column'} xs={5} md={3} xl={2}>
                        <InformationIcon color={ 'primary'} />
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <h3>Data Collections Require Attention</h3>
                                <p>
                                    The data collections below did not complete because intervention is required to support properly importing the data from
                                    the accounting system. Click the import button on a data collection to complete the import. Click the delete button to delete
                                    the data collection.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    !!errorState && (
                        <ErrorBanner
                            errorState={errorState}
                            onDefaultActionButton={() => {
                                setErrorState(undefined);
                            }}
                        />
                    )
                }
                {
                    !portalBusy && !errorState && (
                        <Row>
                            <Col>
                                <SortableTable
                                    brandConfig={brandConfig}
                                    columns={tableColumns}
                                    data={submissions}
                                    sortInformation={sortState}
                                    onSortParametersChanged={setSortState}
                                />
                            </Col>
                        </Row>
                    )
                }
            </Container>
            {
                !!confirmDelete && (
                    <ConfirmModal
                        msg={(
                            <div>
                                <p>Are you sure you want to hide this data collection?</p>
                                <p>You will be able to show it again later if you wish.</p>
                            </div>
                        )}
                        title={'Warning'}
                        modalType={ConfirmModalType.yesno}
                        onTerminalButton={(yes?: boolean) => {
                            if (!!yes) {
                                deleteSubmission(confirmDelete);
                            }
                            setConfirmDelete(undefined);
                        }}
                    />
                )
            }
        </div>
    );
}

export const SubmissionsPendingLenderIntervention = connect<InjectedReduxState, InjectedActionCreators, SubmissionsPendingLenderInterventionProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            allUsers: GetUsers(appState),
            portalBusy: GetPortalBusyState(appState),
            brandConfig: GetBrandConfig(appState),
            loggedInUser: GetLoggedInUser(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...WorkspacesActions,
            ...UIStateActions,
            ...SubmissionHistoryActions,
        },
        dispatch
    )
)(SubmissionsPendingLenderInterventionComponent);
