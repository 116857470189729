import {
    SubmissionPortalStatus,
    Submission,
    SubmissionSearchResponse,
} from '../../Models/Api/strongbox.financialportal';

export enum SubmissionActions {
    LoadSubmission = 'SUBMISSION/LoadSubmission',
    LoadSubmissionComplete = 'SUBMISSION/LoadSubmissionComplete',
    UpdateSubmissionMetadata = 'SUBMISSION/UpdateMetadata',
    UpdateSubmissionStatus = 'SUBMISSION/UpdateStatus',
    UpdateSubmissionStatusComplete = 'SUBMISSION/UpdateStatusComplete',
    LoadSubmissionsPendingIntervention = 'SUBMISSION/LoadSubmissionsPendingIntervention',
    LoadSubmissionsPendingInterventionComplete = 'SUBMISSION/LoadSubmissionsPendingInterventionComplete',
    SubmissionPendingInterventionDeleted = 'SUBMISSION/SubmissionPendingInterventionDeleted',
}

export interface ILoadSubmission {
    type: SubmissionActions.LoadSubmission;
    workspaceId: string;
    submissionId: string;
    metadata?: Map<string, any>;
}

export interface ILoadSubmissionComplete {
    type: SubmissionActions.LoadSubmissionComplete;
    workspaceId: string;
    submissionId: string;
    msg?: string;
    exceptionMsg?: string;
    submission?: Submission;
    metadata?: Map<string, any>;
}

export interface IUpdateMetadata {
    type: SubmissionActions.UpdateSubmissionMetadata;
    workspaceId: string;
    submissionId: string;
    metadata?: Map<string, any>;
}

export interface IUpdateSubmissionStatus {
    type: SubmissionActions.UpdateSubmissionStatus;
    workspaceId: string;
    submissionId: string;
    status: SubmissionPortalStatus;
}

export interface IUpdateSubmissionStatusComplete {
    type: SubmissionActions.UpdateSubmissionStatusComplete;
    workspaceId: string;
    submissionId: string;
    status: SubmissionPortalStatus;
    datasourceNameId: string;
    exceptionMsg?: string;
    submission?: Submission;
}

export interface ILoadSubmissionsPendingIntervention {
    type: SubmissionActions.LoadSubmissionsPendingIntervention;
}

export interface ILoadSubmissionsPendingInterventionComplete {
    type: SubmissionActions.LoadSubmissionsPendingInterventionComplete;
    submissionInfo?: SubmissionSearchResponse;
    exceptionMsg?: string;
}

export interface ISubmissionPendingInterventionDeleted {
    type: SubmissionActions.SubmissionPendingInterventionDeleted;
    submissionId: string;
    workspaceId: string;
}

export type KnownAction =
    ILoadSubmission |
    ILoadSubmissionComplete |
    IUpdateMetadata |
    IUpdateSubmissionStatus |
    IUpdateSubmissionStatusComplete |
    ILoadSubmissionsPendingIntervention |
    ILoadSubmissionsPendingInterventionComplete |
    ISubmissionPendingInterventionDeleted;
