import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { LogMessage, SeverityLevel } from '../../../Utils/Logging';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import '../../Main.scss';

import {
    Submission,
    SubmissionDetail
} from '../../../Models/Api/strongbox.financialportal';

import { SubmissionDetailHeader } from '../SubmissionDetailHeader';

import { ErrorState, ErrorBanner } from '../../ErrorBanner/ErrorBanner';

import { emptyFileDownloadData, FileDownloadData, FileGroupContainer } from '../../FileGroup/FileGroupContainer';

import { noWorkbooksForSubmissionMsg, pathConstants } from '../../../Utils/Constants';

import { ActiveSubmissionDetails } from '../../../Store/SubmissionHistory';

type Props = {
    financialStatementsEnabled: boolean;
    activeDetails: ActiveSubmissionDetails;
    setActiveSubmissionDetails: (details: ActiveSubmissionDetails) => void;
    augmentTitleWithLatestRevision?: boolean;
    onDeleteSubmission: (submissionId: string) => void;
}

export const SubmissionOverview: React.FC<Props> = (props): React.ReactElement => {
    const navigate = useNavigate();

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);

    const [fileDownloadingData, setFileDownloadingData] = React.useState<FileDownloadData>({
        ...emptyFileDownloadData
    });

    const getSubmission = (submissionId: string): Submission | undefined => {
        if (!!props.activeDetails) {
            if (submissionId !== props.activeDetails.submissionSearchResult.submission.id) {
                LogMessage(
                    `Unexpected state in SubmissionOverview, submissionId ${submissionId} parameter is not equal to active submission ID ${props.activeDetails.submissionSearchResult.submission.id}`,
                    SeverityLevel.Warning,
                    {
                        submissionId,
                        activeSubmissionId: props.activeDetails.submissionSearchResult.submission.id
                    }
                );
                console.error('Unexpected error, submissionId parameter not equal to active submission ID in SubmissionOverview');
                return undefined;
            }

            return props.activeDetails.submissionSearchResult.submission;
        } else {
            return undefined;
        }
    }

    const getSubmissionDetail = (submissionId: string): SubmissionDetail | undefined => {
        if (!!props.activeDetails) {
            if (submissionId !== props.activeDetails.submissionDetails.id) {
                LogMessage(
                    `Unexpected state in getting details for a submission, submissionId ${submissionId} not equal to active submission id ${props.activeDetails.submissionDetails.id}`,
                    SeverityLevel.Information,
                    {
                        submissionId,
                        activeSubmissionid: props.activeDetails.submissionDetails.id
                    }
                );
                console.error('Unexpected error, submissionId parameter not equal to active submission ID in SubmissionOverview');
                return undefined;
            }

            return props.activeDetails.submissionDetails;
        } else {
            return undefined;
        }
    }

    const clearDownloadingData = (): void => {
        setFileDownloadingData({
            ...emptyFileDownloadData
        });
    }

    if (!props.activeDetails) {
        navigate(pathConstants.home);
        return (<></>);
    }

    return (
        <Container
            fluid
            className={`free-content-wide-content submissions-list`}
        >
            {
                errorState && (
                    <Row style={{ marginTop: '2em' }}>
                        <Col>
                            <ErrorBanner
                                errorState={{...errorState, actions: [
                                    {
                                        text: 'Dismiss',
                                        id: 'dismiss',
                                        onAction: (actionId: string) => setErrorState(undefined),
                                    }
                                ]}}
                                onDefaultActionButton={() => setErrorState(undefined)}
                            />
                        </Col>
                    </Row>
                )
            }
            {props.activeDetails && (
                <>
                    <SubmissionDetailHeader
                        businessName={props.activeDetails.submissionSearchResult.entity.displayName || ''}
                        pageHeaderPrefix={'Data collection'}
                    />
                    <Row style={{ marginTop: '1em' }}>
                        <Col>
                            <FileGroupContainer
                                containerClass={'history-submission-details-container'}
                                fileDownloadingData={fileDownloadingData}
                                businessId={props.activeDetails.submissionSearchResult.entity.id}
                                getSubmission={getSubmission}
                                getSubmissionDetails={getSubmissionDetail}
                                loadedUserDisplayName={
                                    (props.activeDetails.submissionSearchResult.requestingUser && props.activeDetails.submissionSearchResult.requestingUser.displayName) || ''
                                }
                                submissionDetail={props.activeDetails.submissionDetails}
                                entityInfo={props.activeDetails.submissionSearchResult.entity}
                                retrievingFiles={false}
                                noFilesAvailableMsg={noWorkbooksForSubmissionMsg}
                                onDownloading={(fileDownloadingData) => {
                                    setFileDownloadingData(fileDownloadingData);
                                }}
                                onClearDownloadingData={() => clearDownloadingData()}
                                onEmptyFile={(file) => { }}
                                augmentTitleWithLatestRevision={props.augmentTitleWithLatestRevision}
                                onDeleteSubmission={props.onDeleteSubmission}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
}
