import * as React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import '../Main.scss';

import { ApplicationState } from '../../Store';
import { GetTenantName } from '../../Store/Tenant';
import { MappedWorkspace } from '../../Store/Workspaces';

import { SubmissionHistory } from '../SubmissionHistory/SubmissionHistory';
import { pathConstants } from '../../Utils/Constants';

import { EntityDetailResponse } from '../../Models/Api/strongbox.financialportal';

type InjectedReduxState = {
    tenantStyleClass: string;
};

type InjectedActionCreators = {};

type WorkspaceSubmissionsProps = {
    onRunningJobDismissed?: (financialRecordId: string, workspaceId: string, fromNav: boolean) => void;
    workspaceDetails: EntityDetailResponse | undefined;
}

type Props = WorkspaceSubmissionsProps & InjectedReduxState & InjectedActionCreators;

export const WorkspaceSubmissionsComponent: React.FC<Props> = (props): React.ReactElement => {
    // parameter name, i.e. 'workspaceId' should match the value in routeConstants.workspaceIdParam 
    const {
        workspaceId,
    } = useParams();

    const {
        workspaceDetails
    } = props;

    const [workspaceName, setWorkspaceName] = React.useState<string>('');
    const [showDeletedCollections, setShowDeletedCollections] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!!workspaceDetails) {
            setWorkspaceName(MappedWorkspace(workspaceDetails).displayName || '');
        }
    }, [workspaceDetails]);

    return (
        <div>
            <SubmissionHistory
                allowShowDeleted={true}
                showDeleted={showDeletedCollections}
                onShowDeletedChange={(showThem) => setShowDeletedCollections(showThem)}
                showActions={true}
                workspaceInfo={{
                    workspaceId: workspaceId || '',
                    workspaceName: workspaceName || '',
                }}
                headerContent={(<></>)}
                submissionDetailsBase={pathConstants.workspaceSubmissionDetails}
                onRunningJobDismiss={props.onRunningJobDismissed}
            />
        </div>
    );
}

export const WorkspaceSubmissions = connect<InjectedReduxState, InjectedActionCreators, WorkspaceSubmissionsProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            tenantStyleClass: GetTenantName(appState)
        };

        return result;
    }
)(WorkspaceSubmissionsComponent);
