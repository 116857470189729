import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Col,
    Container,
    Row
} from 'reactstrap';

import '../Main.scss';

import { GetSubmissionDetail, RestoreSubmission, SearchSubmissions } from '../../Services/SubmissionService';
import { WorkspaceActionEnabled, UpdateWorkspaceUsers } from '../../Services/WorkspaceService';
import {
    SubmissionDetail,
    SubmissionSearchResult,
    UserResponse
} from '../../Models/Api/strongbox.financialportal';

import { FetchError } from '../../Utils/FetchWrapper';

import { ApplicationState } from '../../Store';
import {
    GetFinancialStatementsEnabled,
    GetRiskCalcEnabled,
    GetAlwaysCollectBorrowerBusinessInfo,
} from '../../Store/AppSettings';

import { BrandConfig, GetBrandConfig } from '../../Store/Tenant';
import {
    actionCreators as SubmissionHistoryActions,
    GetCurrentResults,
    GetCurrentResultsWorkspaceId,
    GetCurrentScrollPos,
    SubmissionFilter,
} from '../../Store/SubmissionHistory';
import { GetLoggedInUser, GetUsers, LoadAllUsers } from '../../Store/User';
import { GetAllLoadedWorkspaces, LoadWorkspaceDetails, WorkspaceWithMeta } from '../../Store/Workspaces';
import { actionCreators as UIStateActions, GetPortalBusyState } from '../../Store/UIState';

import { WorkspaceRouteInformation } from '../../Utils/WorkspaceRouteInformation';

import { ErrorState, ErrorBanner } from '../ErrorBanner/ErrorBanner';
import { AddModifyWorkspace } from '../AddModifyWorkspace/AddModifyWorkspace';

import { routeConstants } from '../../Utils/Constants';
import { TitleBox } from '../TitleBox/TitleBox';
import { customRange, SubmissionHistoryRange, SubmissionHistoryRangeSelectionType } from '../../Utils/SubmissionHistoryRange';

import { MostRecentConnectionMap, SubmissionHistoryTable } from './SubmissionHistoryTable';
import { SubmissionHistoryRangeSelection } from './SubmissionHistoryRangeSelection';
import { FilterDropdown } from './FilterDropdown';

import { SortableTableSortInformation, SortableTableSortType } from '../SortableTable/SortableTable';

import { LogException } from '../../Utils/Logging';

import {
    actionCreators as SubmissionDetailsActions,
    GetCurrentRange,
    GetCurrentRangeSelectionType,
    GetUnfilteredCurrentResults,
    GetSortColumn,
    GetSortOrder,
    GetSourceFilters,
    GetStatusFilters,
    SubmissionSearchResultWithDate,
    MapSubmissionSearchResult
} from '../../Store/SubmissionHistory';

import { GetRunningJobs, RunningImport } from '../../Store/RunningImports';
import { RunningImportsList } from '../RunningImportsList/RunningImportsList';

type InjectedReduxState = {
    brandConfig: BrandConfig;
    currentScrollPos: number;
    currentSearchResults: SubmissionSearchResultWithDate[];
    hasDeletedResults: boolean;
    mostRecentConnectionMap: MostRecentConnectionMap[];
    workspaceIdOfResults: string | undefined;
    unfilteredSearchResults: SubmissionSearchResultWithDate[];
    historyRangeSelectionType: SubmissionHistoryRangeSelectionType;
    historyRange: SubmissionHistoryRange;
    sortInformation: SortableTableSortInformation;
    sourceFilters: SubmissionFilter[];
    statusFilters: SubmissionFilter[];
    allUsers: UserResponse[];
    loggedInUser?: UserResponse;
    loadedWorkspaces?: WorkspaceWithMeta[];
    portalBusy: boolean;
    runningImports: RunningImport[];
    alwaysCollectBorrowerBusiness: boolean;  // if this is set then we should show the business email and name collected on shared links.
    financialStatementsEnabled: boolean;
    riskAnalysisEnabled: boolean;
};

type InjectedActionCreators = typeof SubmissionDetailsActions & typeof UIStateActions & typeof SubmissionHistoryActions;

/**
 * @param showActions controls whether the list will show action icon on rows
 * that would have them, for instance, a show workbooks action button for rows that
 * have valid financial information to expand workbooks.  Default is false.
 * @param workspaceInfo controls whether the submission list is scoped to a specific
 * business (workspace).  If not provided, there's no specific scope.
 * @param headerContent overrides default header content if provided.  If not provided
 * it will be an h1 tag with 'Submissions for <workspace name>' if businessInfo is 
 * provided or simply 'Submissions' if not.
 * */
type SubmissionHistoryProps = {
    allowShowDeleted?: boolean;
    showActions?: boolean;
    workspaceInfo?: WorkspaceRouteInformation;
    headerContent?: React.ReactElement;
    submissionDetailsBase: string;
    showDeleted?: boolean;
    onRunningJobDismiss?: (financialRecordId: string, workspaceId: string, fromNav: boolean) => void;
    onShowDeletedChange?: (showDeleted: boolean) => void;
}

type Props = SubmissionHistoryProps & InjectedActionCreators & InjectedReduxState;

const SubmissionHistoryComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        allowShowDeleted,
        loggedInUser,
        loadedWorkspaces,
        allUsers,
        showActions,
        workspaceInfo,
        currentSearchResults,
        hasDeletedResults,
        mostRecentConnectionMap,
        currentScrollPos,
        headerContent,
        historyRange,
        historyRangeSelectionType,
        showDeleted,
        sortInformation,
        sourceFilters,
        statusFilters,
        submissionDetailsBase,
        brandConfig,
        unfilteredSearchResults,
        workspaceIdOfResults,
        onShowDeletedChange,
        SetPortalIdle,
        SetPortalWorking,
        portalBusy,
        runningImports,
        onRunningJobDismiss,
        financialStatementsEnabled,
        riskAnalysisEnabled,
        SubmissionRestored,
    } = props;

    const navigate = useNavigate();

    const loadButtonText = 'Load results';
    const historyColumnId = 'historyColumnContainer';

    const [errorState, setErrorStateInternal] = React.useState<ErrorState | undefined>(undefined);
    const setErrorState = (errState: ErrorState | undefined): void => {
        setErrorStateInternal(errState);
        props.SetCurrentScrollPos(0);
    }

    const [haveSearched, setHaveSearched] = React.useState<boolean>(false);

    const [filterConfigOpen, setFilterConfigOpen] = React.useState<boolean>(false);

    const [submissionDetailsKey, setSubmissionDetailsKey] = React.useState<number>(1);
    const [submissionDetails, setSubmissionDetailsInternal] = React.useState<SubmissionDetail[]>([]);
    const setSubmissionDetails = (details: SubmissionDetail[]): void => {
        setSubmissionDetailsInternal(details);
        setSubmissionDetailsKey(submissionDetailsKey + 1);
    }
    const [modifyWorkspaceUsers, setModifyWorkspaceUsers] =
        React.useState<{
            workspace: WorkspaceWithMeta;
            users: UserResponse[];
            submissionId?: string;
        } | undefined>(undefined);

    const [accessToWsDenied, setAccessToWsDenied] =
        React.useState<{
            displayName: string;
            workspaceId: string;
            submissionId?: string;
        } | undefined>(undefined);

    const toggleFilterConfigOpen = (): void => {
        setFilterConfigOpen(!filterConfigOpen);
    }

    const [rangeSelectionOpen, setRangeSelectionOpen] = React.useState<boolean>(false);

    const toggleRangeSelectionOpen = (): void => {
        setRangeSelectionOpen(!rangeSelectionOpen);
    }

    const executeSearch = (initialLoad: boolean, submissionHistoryRange: SubmissionHistoryRange): void => {
        // This should be a sanity check, we don't allow these to be set to null.
        if ((submissionHistoryRange.start === null) || (submissionHistoryRange.end === null)) {
            return;
        }

        setRangeSelectionOpen(false);

        const workspaceId = !!workspaceInfo ? workspaceInfo.workspaceId : undefined;

        SearchSubmissions(
            submissionHistoryRange.start,
            submissionHistoryRange.end,
            workspaceId,
            true
        )
            .then(searchResults => {
                props.SetCurrentScrollPos(0);
                props.SetCurrentHistoryRange(submissionHistoryRange, !!workspaceInfo ? 'workspace' : 'global');
                props.SetCurrentResults(searchResults.map(ssr => MapSubmissionSearchResult(ssr)), workspaceId);
                props.SetSortColumn(1);
                props.SetSortOrder('descending');
            })
            .catch(error => {
                LogException(
                    `Call to SearchSubmissions failed`,
                    error,
                    {
                        workspaceId,
                        searchStart: submissionHistoryRange.start?.toString(),
                        searchEnd: submissionHistoryRange.end?.toString()
                    }
                );

                console.error('Failed on SearchSubmissions call');
                console.error(error);

                setErrorState({
                    severity: 'Error',
                    summaryMessage: 'There was an error searching for financial requests matching your criteria. You may need to try again in a few moments',
                    extraInformation: error.message,
                });
            })
            .finally(() => {
                SetPortalIdle('SubmissionHistory:executeSearch');
            });

        SetPortalWorking('SubmissionHistory:executeSearch');
        setHaveSearched(true);
    }

    React.useEffect(() => {
        if ((workspaceIdOfResults !== workspaceInfo?.workspaceId) ||
            (workspaceInfo?.workspaceId === undefined)) {
            props.SetCurrentResults([], workspaceInfo?.workspaceId);
            executeSearch(true, historyRange);
        } else {
            setHaveSearched(true);
        }
        // executeSearch should not be in the list of dependencies, it will effect a change on every render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceInfo]);

    React.useEffect(() => {
        if (!portalBusy) {
            window.scrollTo(0, currentScrollPos);
        }
    });

    function HandleScroll(this: HTMLElement, ev: Event): void {
        props.SetCurrentScrollPos(window.scrollY);
    }

    React.useEffect(() => {
        LoadAllUsers()
            .then(() => {
            })
            .finally(() => {
                SetPortalIdle('SubmissionHistory:UseEffect');
            });
        SetPortalWorking('SubmissionHistory:UseEffect');

        props.ClearTempSubmissionDetails();

        window.addEventListener('scroll', HandleScroll);

        return () => {
            window.removeEventListener('scroll', HandleScroll);
        }

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmissionClicked = (submissionId: string): void => {
        const scrollTo = window.scrollY;
        const submission = currentSearchResults.find(searchResult => searchResult.ssr.submission.id === submissionId);

        if (!!submission) {
            GetSubmissionDetail(submission.ssr.entity.id, submissionId)
                .then(details => {
                    if (!!details) {
                        props.SetActiveSubmissionDetails({
                            submissionDetails: details,
                            submissionSearchResult: submission.ssr,
                        });

                        const linkSubmissionId = escape(submission.ssr.submission.id);
                        const linkEntityId = escape(submission.ssr.entity.id);
                        const urlPath = `${submissionDetailsBase}/${routeConstants.submissionDetailsOverview}/${linkEntityId}/${linkSubmissionId}`;


                        props.SetCurrentScrollPos(scrollTo);
                        navigate(urlPath);
                    } else {
                        setErrorState({
                            severity: 'Error',
                            summaryMessage: 'An error occurred retrieving data collection details',
                            extraInformation: 'Make sure you have permission to view this data collection'
                        });
                    }
                })
                .catch((exception: FetchError) => {
                    // if we get unauthorized then allow user to add themselves to list of users for the workspace.
                    // We are on this page in one of two ways, either we're scoped to the submissions for a workspace
                    // in which case we know this user already has access (we double check that we are in fact scoped
                    // below) and this will not occur or we're on the global submissions page in which case they  
                    // probably have authority to do this.  We double check this and default to going for it if for some
                    // reason we haven't already loaded the workspace.   If we can't double check and if for some reason 
                    // they don't have permissions to access this workspace, the call to get workspace details that occurs 
                    // immediately upon opting to add themselves to the workspace will fail and they'll receive an error.  

                    let loadedWorkspace = undefined;
                    if (!!loadedWorkspaces) {
                        loadedWorkspace = loadedWorkspaces.find(workspace => workspace.id === submission.ssr.entity.id);
                    }

                    if ((exception.status === 401) && 
                        (!workspaceInfo) &&
                        (!loadedWorkspace || WorkspaceActionEnabled(loadedWorkspace, 'CanManageWorkspaceUsers')))
                    {
                        setAccessToWsDenied({
                            displayName: submission.ssr.entity.displayName || '',
                            workspaceId: submission.ssr.entity.id,
                            submissionId,
                        });
                        // We might be down in the middle of the list somewhere and we want the message to be visible.
                        props.SetCurrentScrollPos(0);
                    } else {
                        LogException(
                            `Failed retrieving details for submission: ${submission.ssr.entity.id} submissionId: ${submissionId}`,
                            exception,
                            {
                                submissionClicked: submissionId,
                                workspaceId: submission.ssr.entity.id
                            }
                        );
                        console.error('Failed retrieving details for submission');
                        console.error(exception);

                        setErrorState({
                            severity: 'Error',
                            summaryMessage: 'An error occurred retrieving data collection details',
                            extraInformation: 'Make sure you have permission to view this data collection'
                        });
                    }
                })
                .finally(() => {
                    SetPortalIdle('SubmissionHistory:onSubmissionClicked');
                })

            SetPortalWorking('SubmissionHistory:onSubmissionClicked');
        }
    }

    const customRangeShowing = React.useMemo(() => {
        return historyRangeSelectionType === customRange;
    }, [historyRangeSelectionType])

    const retrieveSubmissionDetails = (ssr: SubmissionSearchResult): void => {
        const iSubmission = submissionDetails.findIndex(sub => sub.id === ssr.submission.id);
        if (iSubmission !== -1) {
            return;
        }
        GetSubmissionDetail(ssr.entity.id, ssr.submission.id)
            .then(details => {
                if (!!details) {
                    const newMap = submissionDetails.slice();
                    newMap.push(details);
                    setSubmissionDetails(newMap);
                } else {
                    setErrorState({
                        severity: 'Error',
                        summaryMessage: 'Unable to retrieve workbooks for this data collection.',
                        extraInformation: 'Please ensure you have sufficient permissons to access these documents.'
                    });
                }
            })
            .catch(exception => {
                setErrorState({
                    severity: 'Error',
                    summaryMessage: 'Unable to retrieve workbooks for this data collection.',
                    extraInformation: 'Please ensure you have sufficient permissons to access these documents.'
                });
            })
            .finally(() => SetPortalIdle('SubmissionHistory:retrieveSubmissionDetails'));

        SetPortalWorking('SubmissionHistory:retrieveSubmissionDetails');

        /* setWorkingAllowList(true); */
    }

    const hideSubmissionDetails = (ssr: SubmissionSearchResult): void => {
        const iSubmission = submissionDetails.findIndex(sub => sub.id === ssr.submission.id);
        if (iSubmission === -1) {
            return;
        }

        const newMap = submissionDetails.slice(0, iSubmission).concat(submissionDetails.slice(iSubmission + 1));
        setSubmissionDetails(newMap);
    }

    const updateWorkspaceUsers = async (
        userIds: string[],
        onComplete: () => void,
        workspace: WorkspaceWithMeta
    ): Promise<void> => {
        SetPortalWorking('SubmissionHistory:updateWorkspaceUsers');

        const workspaceId = workspace.id;

        try {
            await UpdateWorkspaceUsers(workspaceId, userIds);
        } catch (exception) {
            LogException(
                `Failed adding users to newly created workspace`,
                new Error(`Failed adding users to newly created workspace`),
                {
                    workspaceId,
                    users: userIds
                }
            );
        } finally {
            SetPortalIdle('SubmissionHistory:updateWorkspaceUsers');
        }

        onComplete();
    }

    const ManageUsersAction = 'managerUsersAction';

    const accessDeniedActions = (actionId: string, workspaceId: string, workspaceName: string, submissionId?: string): void => {
        setAccessToWsDenied(undefined);
        if (actionId === ManageUsersAction) {
            LoadWorkspaceDetails(workspaceId, allUsers)
                .then(wsDetails => {
                    if (!!wsDetails) {
                        setModifyWorkspaceUsers({
                            workspace: {
                                ...wsDetails.entityDetails,
                                displayDate: '',
                                displayDateFull: '',
                                lastSyncDisplayDate: '',
                                lastSyncDisplayDateFull: ''
                            },
                            users: wsDetails?.userInformation.users || [],
                            submissionId,
                        });
                    }
                })
                .catch(exception => {
                    setErrorState({
                        summaryMessage: 'Unable to retrieve details for this workspace',
                        extraInformation: exception.toString(),
                    });
                    LogException(
                        `Error retrieving details for a workspace to allow logged in user to add themselves to its users ${workspaceId}`,
                        exception,
                        {
                            workspaceId
                        }
                    );
                })
                .finally(() => {
                    SetPortalIdle('SubmissionHistory:renderAccessibleContent');
                })
            SetPortalWorking('SubmissionHistory:renderAccessibleContent');
        }
    }

    const restoreSubmission = (workspaceId: string, submissionId: string) => {
        RestoreSubmission(submissionId)
            .then(() => {
                if (workspaceId) {
                    SubmissionRestored(workspaceId, submissionId);
                }
            })
            .catch(() => {
                setErrorState({
                    severity: 'Error',
                    summaryMessage: `There was an error restoring the submission. The submission has not been restored`,
                });
            })
    }

    const renderAccessibleContent = (): React.ReactElement => {
        return (
            <Container
                fluid
                className={`submissions-list`}
            >
                {
                    errorState && (
                        <Row style={{ marginTop: '2em' }}>
                            <Col>
                                <ErrorBanner
                                    errorState={{...errorState, actions: [
                                        {
                                            text: 'Dismiss',
                                            id: 'actionDismiss',
                                            onAction: (actionId: string) => setErrorState(undefined)
                                        }
                                    ]}}
                                    onDefaultActionButton={() => setErrorState(undefined)}
                                />
                            </Col>
                        </Row>
                    )
                }
                {
                    accessToWsDenied && (
                        <Row style={{ marginTop: '2em' }}>
                            <Col>
                                <ErrorBanner
                                    errorState={{
                                        extraInformation: `You don't currently have permission to view details for the workspace (${accessToWsDenied.displayName}) containing this data collection. Click 'Manage workspace users' below where you may add yourself to this workspace.`,
                                        summaryMessage: 'Access to workspace denied',
                                        severity: 'Notification',
                                        actions: [
                                            {
                                                text: 'Dismiss',
                                                id: 'dismissAction',
                                                onAction: (actionId: string) =>
                                                    accessDeniedActions(
                                                        actionId,
                                                        accessToWsDenied.workspaceId,
                                                        accessToWsDenied.displayName,
                                                        accessToWsDenied.submissionId
                                                    )
                                            },
                                            {
                                                text: 'Manage workspace users',
                                                id: ManageUsersAction,
                                                onAction: (actionId: string) =>
                                                    accessDeniedActions(
                                                        actionId,
                                                        accessToWsDenied.workspaceId,
                                                        accessToWsDenied.displayName,
                                                        accessToWsDenied.submissionId
                                                    )
                                            }
                                        ],
                                    }}
                                    onDefaultActionButton={() => setAccessToWsDenied(undefined)}
                                />
                            </Col>
                        </Row>
                    )
                }
                {
                    !!modifyWorkspaceUsers && (
                        <AddModifyWorkspace
                            loggedOnUser={loggedInUser}
                            workspace={modifyWorkspaceUsers.workspace}
                            currentUsers={modifyWorkspaceUsers.users}
                            open={true}
                            allUsers={props.allUsers}
                            onCancel={() => {
                                setModifyWorkspaceUsers(undefined);
                            }}
                            onSubmit={async (users) => {
                                await updateWorkspaceUsers(
                                    users,
                                    () => {
                                        // We can get here by the user clicking on a submission belonging to a ws to which they
                                        // are not allowed to access but they have credentials to add themselves.  If that happens
                                        // at this point now that they've granted themselves access, attempt to navigate to the
                                        // submission (assuming they're in the list of users)
                                        if ((!!modifyWorkspaceUsers.submissionId) && (!!onSubmissionClicked)) {
                                            if (users.find(userId => userId === loggedInUser?.id)) {
                                                onSubmissionClicked(modifyWorkspaceUsers.submissionId)
                                            }
                                        }
                                        setModifyWorkspaceUsers(undefined);
                                    },
                                    modifyWorkspaceUsers.workspace
                                )
                            }}
                        />
                    )
                }
                <Row>
                    <Col>
                        <div className={'portal-header-region spaced-row'}>
                            {headerContent}
                            {!headerContent && (
                                <h1 style={{ margin: '0px' }}>{`Data Collection Report${!!workspaceInfo ? ` for ${workspaceInfo.workspaceName}` : ''}`}</h1>
                            )}
                        </div>
                    </Col>
                </Row>
                {
                    !!workspaceInfo &&
                    !!runningImports.find(job => job.workspaceId === workspaceInfo.workspaceId) &&
                    (
                        <div style={{ marginBottom: '3em'}}>
                            <TitleBox
                                title={'Pending data collections'}
                                containerStyle={{ marginBottom: '3em' }}
                            >
                                <RunningImportsList
                                    scopeWorkspaceId={workspaceInfo.workspaceId}
                                    hideWorkspaceInfo={true}
                                    onJobDismiss={onRunningJobDismiss}
                                />
                            </TitleBox>
                        </div>
                    )
                }
                {!portalBusy && (
                    <Row style={{ marginTop: '15px' }}>
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={customRangeShowing ? 12 : 6}
                            xl={customRangeShowing ? 'auto' : 'auto'}
                        >
                            <SubmissionHistoryRangeSelection
                                onSetSubmissionHistoryRange={(newRange: SubmissionHistoryRange) => props.SetCurrentHistoryRange(newRange, !!workspaceInfo ? 'workspace' : 'global')}
                                onRangeTypeChanged={(newRangeType: SubmissionHistoryRangeSelectionType, newRange: SubmissionHistoryRange) => {
                                    props.SetCurrentHistoryRange(newRange, !!workspaceInfo ? 'workspace' : 'global');
                                    props.SetCurrentRangeSelectionType(newRangeType, !!workspaceInfo ? 'workspace' : 'global');
                                }}
                                searchButtonText={loadButtonText}
                                onExecuteSearch={(range) => executeSearch(false, range)}
                                selectionType={historyRangeSelectionType}
                                submissionHistoryRange={historyRange}
                                rangeSelectionOpen={rangeSelectionOpen}
                                toggleRangeSelectionOpen={toggleRangeSelectionOpen}
                                closeRangeSelection={() => {
                                    setRangeSelectionOpen(false);
                                }}
                            />
                        </Col>
                        <Col xs={'auto'}>
                            <FilterDropdown
                                statusFilters={statusFilters}
                                sourceFilters={sourceFilters}
                                onSourceFilterStateChange={(id: string, newState: boolean) => {
                                    setFilterConfigOpen(false);
                                    props.SetSourceFilter(id, newState)
                                }}
                                onStatusFilterStateChange={(id: string, newState: boolean) => {
                                    setFilterConfigOpen(false);
                                    props.SetStatusFilter(id, newState)
                                }}
                                filterConfigOpen={filterConfigOpen}
                                toggleFilterConfigOpen={toggleFilterConfigOpen}
                                closeFilterConfigOpen={() => setFilterConfigOpen(false)}
                            />
                        </Col>
                        { !!allowShowDeleted && hasDeletedResults && (
                            <Col className={'vcentered-content-col'}>
                                <Row>
                                    <Col>
                                        <input
                                            type={'checkbox'}
                                            id={'showDeletedDataCollections'}
                                            name={'showDeletedDataCollections'}
                                            checked={!!showDeleted}
                                            onChange={e => {
                                                onShowDeletedChange && onShowDeletedChange(e.currentTarget.checked);
                                            }}
                                        >
                                        </input>
                                        <label
                                            htmlFor={'showDeletedDataCollections'}
                                            style={{
                                                marginLeft: '15px'
                                            }}
                                        >
                                            Show hidden data collections
                                        </label>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                )}
                <Row key={submissionDetailsKey.toString()}>
                    <Col id={historyColumnId}>
                        <SubmissionHistoryTable
                            alwaysCollectBorrowerBusinessInfo={props.alwaysCollectBorrowerBusiness}
                            showActions={showActions}
                            hideWorkspaceName={!!workspaceInfo}
                            actionText={loadButtonText}
                            hasExecutedSearch={haveSearched}
                            style={{
                                marginTop: '2em'
                            }}
                            submissions={currentSearchResults}
                            mostRecentConnections={mostRecentConnectionMap}
                            details={submissionDetails}
                            brandConfig={brandConfig}
                            onSubmissionClicked={onSubmissionClicked}
                            onSort={(submissions) => { 
                                props.SetCurrentResults(submissions)
                            }}
                            overrideSort={sortFunc => sortFunc(unfilteredSearchResults)}
                            onSortParametersChanged={(sortInformation: SortableTableSortInformation) => {
                                props.SetSortColumn(sortInformation.sortColumn);
                                props.SetSortOrder(sortInformation.sortType);
                            }}
                            tableSortInformation={sortInformation}
                            onShowDocuments={retrieveSubmissionDetails}
                            onHideDocuments={hideSubmissionDetails}
                            financialStatementsEnabled={financialStatementsEnabled}
                            riskAnalysisEnabled={riskAnalysisEnabled}
                            onSetErrorState={setErrorState}
                            working={portalBusy}
                            onSetImporting={working => working ? SetPortalWorking('SubmissionHistoryTableProp') : SetPortalIdle('SubmissionHistoryTableProp')}
                            onRestoreSubmission={restoreSubmission}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }


    return renderAccessibleContent();
}

export const SubmissionHistory = connect<InjectedReduxState, InjectedActionCreators, SubmissionHistoryProps, ApplicationState>(
    (appState: ApplicationState, props: SubmissionHistoryProps) => {
        let sortType: SortableTableSortType = 'ascending';
        const storedSortType = GetSortOrder(appState);

        if ((storedSortType === 'ascending') || (storedSortType === 'descending') || (storedSortType === 'none')) {
            sortType = storedSortType;
        }

        const sortInformation: SortableTableSortInformation = {
            sortColumn: GetSortColumn(appState),
            sortType,
        }
        if (sortInformation.sortColumn < 1) {
            sortInformation.sortColumn = 1;
        }

        const mostRecentConnections: MostRecentConnectionMap[] = [];

        const currentSearchResults = GetCurrentResults(appState, !!props.showDeleted);
        const unfilteredSearchResults = GetUnfilteredCurrentResults(appState);

        const result = {
            brandConfig: GetBrandConfig(appState),
            currentScrollPos: GetCurrentScrollPos(appState),
            currentSearchResults,
            hasDeletedResults: !!unfilteredSearchResults.find(s => !!s.ssr.submission.deleted),
            mostRecentConnectionMap: mostRecentConnections,
            workspaceIdOfResults: GetCurrentResultsWorkspaceId(appState),
            unfilteredSearchResults,
            historyRangeSelectionType: GetCurrentRangeSelectionType(appState, !!props.workspaceInfo ? 'workspace' : 'global'),
            historyRange: GetCurrentRange(appState, !!props.workspaceInfo ? 'workspace' : 'global'),
            sourceFilters: GetSourceFilters(appState),
            statusFilters: GetStatusFilters(appState),
            sortInformation,
            allUsers: GetUsers(appState),
            loggedInUser: GetLoggedInUser(appState),
            loadedWorkspaces: GetAllLoadedWorkspaces(appState),
            portalBusy: GetPortalBusyState(appState),
            runningImports: GetRunningJobs(appState),
            alwaysCollectBorrowerBusiness: GetAlwaysCollectBorrowerBusinessInfo(appState),
            financialStatementsEnabled: GetFinancialStatementsEnabled(appState),
            riskAnalysisEnabled: GetRiskCalcEnabled(appState),
        };

        result.currentSearchResults.forEach(sr => {
            const iExisting = result.mostRecentConnectionMap.findIndex(el => !!sr.ssr.submission.datasourceNameId && el.datasourceNameId === sr.ssr.submission.datasourceNameId);
            if (iExisting === -1) {
                if (!!sr.ssr.submission.datasourceNameId) {
                    result.mostRecentConnectionMap.push({
                        datasourceNameId: sr.ssr.submission.datasourceNameId,
                        creationTime: new Date(sr.ssr.submission.creationTime),
                        submissionId: sr.ssr.submission.id
                    });
                }
            } else {
                const testDate = new Date(sr.ssr.submission.creationTime);
                if (testDate.getTime() > result.mostRecentConnectionMap[iExisting].creationTime.getTime()) {
                    result.mostRecentConnectionMap[iExisting].submissionId = sr.ssr.submission.id;
                    result.mostRecentConnectionMap[iExisting].creationTime = testDate;
                }
            }
        });

        result.mostRecentConnectionMap = mostRecentConnections;

        return result;
    },
    {
        ...SubmissionDetailsActions,
        ...UIStateActions,
        ...SubmissionHistoryActions,
    }
)(SubmissionHistoryComponent);


