import '../Main.scss';

import * as React from 'react';
import { connect } from 'react-redux';

import {
    Button,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
} from 'reactstrap';

import {
    Stepper,
    Step,
    StepButton,
    Tooltip,
} from '@mui/material';

import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBackward from '@mui/icons-material/ArrowBack';

import {
    actionCreators as NewDataCollectionParametersActionCreators,
    GetCreationOptions,
    GetCollectionGroupings,
} from '../../Store/NewDataCollectionParameters';
import {
    TenantAttributedParagraphSet,
    ResourceName,
    RetrieveResource
} from '../../Store/Tenant';
import {
    actionCreators as ImportFinancialsActionCreators,
    GetImportCustomerFinancialsActive,
} from '../../Store/ImportFinancials';
import {
    GetMaxYearsInAdditionToFYTDForCollection,
    GetNumMonthsInImportYTDDropdown,
    GetDisableAdditionalDocUpload,
    GetDisableProvideuserCopy,
    DirectLinkingDisabled,
    GetDisableBasisOfAccounting,
    GetDisableAnonymizeResults,
    GetAllUserOptionsDisabled,
    NoTimeRestrictionForDataCollection,
    GetShareableLinkParamsFlowSwitch,
    FileTemplateUploadEnabled,
    GetDynamicTenantSelectionSwitch,
} from '../../Store/AppSettings';
import { ApplicationState } from '../../Store';

import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';
import { AccountingCollectionGroup, knownAccountingGroupIds } from '../../Models/AccountingCollectionGroup';

import { MostRecentDate } from '../../Utils/MostRecentDate';

import { BasisOfAccountingPreference } from '../../Models/Api/strongbox.financialportal';

import { LinkMode } from '../../Store/ImportFinancials';
import { NewDataCollectionContentGrouping } from '../../Store/NewDataCollectionParameters';
import { ChooseImportModeModal } from '../ChooseImportMode/ChooseImportModeModal';

import { CollectFinancialsImportParameters, OnOffSettings } from './CollectFinancialsImportParameters';
import { SelectImportDataItems } from './SelectImportDataItems';
import { StrongboxTOU } from '../StrongboxTOU';

import { ImportModeSelector } from '../ChooseImportMode/ImportModeSelector';

import { getUsableDay } from '../../Utils/DateUtils';

import { ConfirmModal, ConfirmModalType } from '../ConfirmModal/ConfirmModal';

type InjectedReduxState = {
    importCustomerFinancialsActive: boolean;
    importOptions: PortalCreateSubmissionRequestOptions;
    shareableLinkParamsActive: boolean;
    maxYearsInAdditionToFYTD: number;
    numberOfMonthsInDropdown?: number;
    disableFileUpload: boolean;
    disableProvideUserCopy: boolean;
    disableDirectLinking: boolean;
    disableBasisOfAccounting: boolean;
    disableAnonymizeResults: boolean;
    allUserOptionsDisabled: boolean;
    collectionContentGroups: NewDataCollectionContentGrouping[];
    collectionGroupDescriptions: TenantAttributedParagraphSet;
    collectionGroupDescriptionsExcel: TenantAttributedParagraphSet;
    noTimeRestrictionForDataCollection: boolean;
    additionalMessagingSelectAccountingDataPage: TenantAttributedParagraphSet;
    excelImportEnabled: boolean;
    dynamicTenantSelectionEnabled: boolean;
};

type InjectedActionCreators = typeof ImportFinancialsActionCreators & typeof NewDataCollectionParametersActionCreators;

type ImportCustomerFinancialsContainerProps = {
    directOnly: boolean;
    workspaceName: string;
    onSubmit?: (linkMode: LinkMode, options: PortalCreateSubmissionRequestOptions, allowPkgSelection: boolean) => void;
    onCancel?: () => void;
    externallyDisabled?: boolean;
};

type ImportStep = {
    key: string;
    label: string;
    renderIndex: number;
}

type Props = ImportCustomerFinancialsContainerProps & InjectedActionCreators & InjectedReduxState;

const ImportCustomerFinancialsComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        additionalMessagingSelectAccountingDataPage,
        noTimeRestrictionForDataCollection,
        directOnly,
        disableFileUpload,
        disableProvideUserCopy,
        disableAnonymizeResults,
        disableBasisOfAccounting,
        allUserOptionsDisabled,
        importCustomerFinancialsActive,
        onCancel,
        onSubmit,
        importOptions,
        ToggleImportCustomerFinancialsActive,
        disableDirectLinking,
        maxYearsInAdditionToFYTD,
        shareableLinkParamsActive,
        numberOfMonthsInDropdown,
        collectionContentGroups,
        collectionGroupDescriptions,
        collectionGroupDescriptionsExcel,
        InitializeCollectionGroupings,
        excelImportEnabled,
        dynamicTenantSelectionEnabled,
    } = props;


    const renderChooseImportType = (): React.ReactElement => {
        return (
            <ChooseImportModeModal
                importType={linkMode}
                excelImportEnabled={excelImportEnabled}
                onChangeImportType={onLinkModeChange}
            />
        );
    }

    const renderChooseBasicSettings = (): React.ReactElement => {
        return (
            <CollectFinancialsImportParameters
                showShareableLinkParameters={shareableLinkParamsActive}
                directLink={linkMode === LinkMode.direct}
                disableFileUpload={disableFileUpload}
                disableProvideUserCopy={disableProvideUserCopy}
                disableBasisOfAccounting={disableBasisOfAccounting}
                disableAnonymizedResults={disableAnonymizeResults}
                allUserOptionsDisabled={allUserOptionsDisabled}
                parameters={importOptionsInUse}
                maxCollectionPeriods={maxYearsInAdditionToFYTD}
                onMostRecentMonthChange={onMostRecentMonthChange}
                onPeriodsToCollectChanged={onPeriodsToCollectChanged}
                onToggleOnOffSetting={onToggleOnOffSetting}
                onBasisOfAccountingChanged={onBasisOfAccountingChanged}
                numberOfMonthsInDropdown={numberOfMonthsInDropdown}
                noTimeRestrictionForDataCollection={noTimeRestrictionForDataCollection}
                linkMode={linkMode}
            />
        )
    }

    const [collectionContentGroupsInUse, setCollectionContentGroupsInUse] = React.useState<NewDataCollectionContentGrouping[]>([]);

    const renderSelectAccountingDataToCollect = (): React.ReactElement => {
        return (
            <SelectImportDataItems
                collectionContentGroups={collectionContentGroupsInUse}
                onContentEnablementChange={onCollectionGroupEnablementChange}
                additionalMessaging={linkMode !== LinkMode.excel ? additionalMessagingSelectAccountingDataPage : undefined}
            />
        );
    }

    const [linkMode, setLinkMode] = React.useState<LinkMode>(LinkMode.shareable);

    // mostRecentPeriodMonth will be set immediately by a callback onMostRecentMonthChange.
    // If the year is lte 0 and month is lt 0, the modal will treat this as undefined and
    // that will trigger a callback. The parameters to the callback will be the
    // first available date shown in the dropdown from which the user selects a month.

    const [importOptionsInUse, setImportOptionsInUse] = React.useState<PortalCreateSubmissionRequestOptions>(importOptions);

    const [currentStep, setCurrentStep] = React.useState<number>(0);

    const [errorNoGroupsSelected, setErrorNoGroupsSelected] = React.useState<boolean>(false);

    const [stepParameters, setStepParameters] = React.useState<{
        totalSteps: number;
        importStepChooseMode: number;
        importStepChooseBasicOptions: number;
        importStepChooseAccountingGroups: number;
    }>({
        totalSteps: 3,
        importStepChooseMode: 0,
        importStepChooseBasicOptions: 1,
        importStepChooseAccountingGroups: 2,
    })

    const backButtonDisabled = React.useMemo(() => {
        return currentStep <= 0;
    }, [currentStep]);

    // This is a little weird.  Because of the way React handles dependencies,
    // if we keep this in importSteps, unless we enumerate every depedency that
    // gets passed to each of the components in these methods, we end up with
    // invocations that have stale parameters. It's more efficient to redirect
    // through this array than specifying the functions as a property in the
    // ImportStep object.

    const stepRenderers: (() => React.ReactElement)[] = [
        renderChooseImportType,
        renderChooseBasicSettings,
        renderSelectAccountingDataToCollect
    ];

    const fullImportSteps = [
        {
            key: 'selectMode',
            label: 'Import Type',
            renderIndex: 0,
        },
        {
            key: 'chooseBasicOptions',
            label: 'Basic options',
            renderIndex: 1,
        },
        {
            key: 'selectAccountingData',
            label: 'Accounting data to collect',
            renderIndex: 2,
        }
    ];

    const noModeImportSteps = [
        {
            key: 'chooseBasicOptions',
            label: 'Basic options',
            renderIndex: 1,
        },
        {
            key: 'selectAccountingData',
            label: 'Accounting data to collect',
            renderIndex: 2,
        }
    ];

    const [importSteps, setImportSteps] = React.useState<ImportStep[]>(fullImportSteps);

    React.useEffect(() => {
        const today = new Date();

        setImportOptionsInUse({
            ...importOptionsInUse,
            mostRecentPeriodMonth: today.getMonth(),
            mostRecentPeriodYear: today.getFullYear(),
            mostRecentPeriodDay: today.getDate(),
        });
        // I want this to run like componentDidMount so this is the correct dependency list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!(directOnly || disableDirectLinking)) {
            setImportSteps(fullImportSteps.slice());
            setStepParameters({
                totalSteps: fullImportSteps.length,
                importStepChooseMode: 0,
                importStepChooseBasicOptions: 1,
                importStepChooseAccountingGroups: 2,
            });
            setCurrentStep(stepParameters.importStepChooseMode);
        } else {
            setImportSteps(noModeImportSteps.slice());
            setStepParameters({
                totalSteps: noModeImportSteps.length,
                importStepChooseMode: -1,
                importStepChooseBasicOptions: 0,
                importStepChooseAccountingGroups: 1,
            });
            setCurrentStep(stepParameters.importStepChooseBasicOptions);
        }
        // These are the only dependencies I really want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableDirectLinking, directOnly])

    React.useEffect(() => {
        if (importCustomerFinancialsActive) {
            setLinkMode(directOnly ? LinkMode.direct : LinkMode.shareable);
            setCurrentStep(disableDirectLinking || directOnly ? stepParameters.importStepChooseBasicOptions : stepParameters.importStepChooseMode);
        }

        // These are the only dependencies I really want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directOnly, disableDirectLinking, DirectLinkingDisabled, importCustomerFinancialsActive, stepParameters, importSteps])

    React.useEffect(() => {
        if (linkMode === LinkMode.excel) {
            InitializeCollectionGroupings(collectionGroupDescriptionsExcel, dynamicTenantSelectionEnabled);
            setCollectionContentGroupsInUse([]);
        } else {
            InitializeCollectionGroupings(collectionGroupDescriptions, dynamicTenantSelectionEnabled);
            setCollectionContentGroupsInUse([]);
        }
        // These are the only dependencies I really want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkMode]);

    React.useEffect(() => {
        let groupsToSet = collectionContentGroups.slice();

        if (linkMode === LinkMode.excel) {
            groupsToSet = collectionContentGroups.map((grp) => {
                if (grp.collectionGroup.id === knownAccountingGroupIds.financialStatements) {
                    return {
                        ...grp,
                        selected: true,
                        collectionGroup: {
                            ...grp.collectionGroup,
                            readOnly: true,
                            collect: true,
                        }
                    };
                } else {
                    return {
                        ...grp,
                        selected: false,
                        collectionGroup: {
                            ...grp.collectionGroup,
                            readOnly: true,
                            collect: false,
                        }
                    };
                }
            });
        }

        setCollectionContentGroupsInUse(groupsToSet);

        // collectionContentGroups and linkMode are the only variables that need to be in the dependency list.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionContentGroups, linkMode]);

    const onLinkModeChange = (linkMode: LinkMode): void => {
        setLinkMode(linkMode);
        if (!!importOptionsInUse) {
            setImportOptionsInUse({
                ...importOptionsInUse,
                allowUserUpload: false,
                provideUserCopy: false,
            })
        }
    }

    const toggleModal = (): void => {
        ToggleImportCustomerFinancialsActive();
        onCancel && onCancel();
    }

    const onMostRecentMonthChange = (newValue: MostRecentDate): void => {
        // If we pass undefined for parameters down to ImportCustomerFinancialsModal, this
        // will never be called so this is really just a sanity check.
        if (!!importOptionsInUse) {
            if ((importOptionsInUse.mostRecentPeriodYear === undefined) || (importOptionsInUse.mostRecentPeriodMonth === undefined)) {
                setImportOptionsInUse({
                    ...importOptionsInUse,
                    mostRecentPeriodMonth: newValue.month,
                    mostRecentPeriodYear: newValue.year,
                    mostRecentPeriodDay: newValue.day,
                });
            } else {
                // If the month and/or year are changing, set the day to the last usable day.
                // That's probably the most reasonable balance between keeping the correct end
                // of the month (e.g. going from a month with 30->31 days) and usability where
                // the user actually chose a non-eom day.
                let newDay = newValue.day;

                if ((newValue.month !== importOptionsInUse.mostRecentPeriodMonth) || (newValue.year !== importOptionsInUse.mostRecentPeriodYear)) {
                    newDay = getUsableDay(newValue.month, undefined, newValue.year);
                }

                setImportOptionsInUse({
                    ...importOptionsInUse,
                    mostRecentPeriodMonth: newValue.month,
                    mostRecentPeriodYear: newValue.year,
                    mostRecentPeriodDay: newDay,
                });
            }
        }
    }

    const onPeriodsToCollectChanged = (newValue: number): void => {
        // If we pass undefined for parameters down to ImportCustomerFinancialsModal, this
        // will never be called so this is really just a sanity check.
        if (!!importOptionsInUse) {
            setImportOptionsInUse({
                ...importOptionsInUse,
                fullFiscalYearsToCollect: newValue
            });
        }
    }

    const changeCollectionKey = (collectionGroups: NewDataCollectionContentGrouping[], key: string, enabled: boolean): void => {
        const groupIndex = collectionGroups.findIndex(g => g.collectionGroup.id === key);
        if (groupIndex === -1) {
            return;
        }

        const group = collectionGroups[groupIndex];
        collectionGroups[groupIndex] = {
            ...group,
            selected: enabled,
        }
    }

    const onCollectionGroupEnablementChange = (key: string, enabled: boolean): void => {
        const newCollectionGroups = collectionContentGroupsInUse.slice();

        switch (key) {
            case knownAccountingGroupIds.financialStatements:
                changeCollectionKey(newCollectionGroups, knownAccountingGroupIds.financialStatements, enabled);
                break;
            case knownAccountingGroupIds.transactions:
                changeCollectionKey(newCollectionGroups, knownAccountingGroupIds.transactions, enabled);
                break;
            case knownAccountingGroupIds.accountsReceivable:
                changeCollectionKey(newCollectionGroups, knownAccountingGroupIds.accountsReceivable, enabled);
                break;
            case knownAccountingGroupIds.accountsPayable:
                changeCollectionKey(newCollectionGroups, knownAccountingGroupIds.accountsPayable, enabled);
                break;
        }
        setCollectionContentGroupsInUse(newCollectionGroups);
    }

    const onBasisOfAccountingChanged = (newValue: BasisOfAccountingPreference): void => {
        if (!!importOptionsInUse) {
            setImportOptionsInUse({
                ...importOptionsInUse,
                basisOfAccountingPreference: newValue
            });
        }
    }

    const onToggleOnOffSetting = (setting: OnOffSettings): void => {
        // If we pass undefined for parameters down to ImportCustomerFinancialsModal, this
        // will never be called so this is really just a sanity check.
        switch (setting) {
            case OnOffSettings.allowUserUpload:
                if (!!importOptionsInUse) {
                    setImportOptionsInUse({
                        ...importOptionsInUse,
                        allowUserUpload: !importOptionsInUse.allowUserUpload,
                    });
                }
                break;
            case OnOffSettings.anonymizeCustomersAndVendors:
                if (!!importOptionsInUse) {
                    setImportOptionsInUse({
                        ...importOptionsInUse,
                        anonymizeCustomersAndVendors: !importOptionsInUse.anonymizeCustomersAndVendors,
                    });
                }
                break;
            case OnOffSettings.userGetsCopy:
                if (!!importOptionsInUse) {
                    setImportOptionsInUse({
                        ...importOptionsInUse,
                        provideUserCopy: !importOptionsInUse.provideUserCopy,
                    });
                }
                break;
            default:
                break;
        }
    }

    const startImport = (allowPkgSelection: boolean): void => {
        const atLeast1GroupSelected = collectionContentGroupsInUse.find(g => g.selected);

        if (!atLeast1GroupSelected) {
            setErrorNoGroupsSelected(true);
            return;
        }

        let submitParameters = {
            ...importOptionsInUse
        }

        if (linkMode !== LinkMode.shareable) {
            // Don't really need to do this but it's more complete
            if (!!importOptionsInUse) {
                setImportOptionsInUse({
                    ...importOptionsInUse,
                    allowUserUpload: false,
                    provideUserCopy: false,
                });
            }
        }

        submitParameters.accountingCollectionGroups = new Map<string, AccountingCollectionGroup>();

        collectionContentGroupsInUse.forEach(group => {
            submitParameters.accountingCollectionGroups.set(group.collectionGroup.id, {
                id: group.collectionGroup.id,
                collect: group.selected
            });
        });

        submitParameters.hasShareableLink = (linkMode === LinkMode.shareable);

        onSubmit && onSubmit(linkMode, submitParameters, allowPkgSelection);

        toggleModal();
    }

    const handleOnSubmit = (allowPkgSelection: boolean): void => {
        if (currentStep >= stepParameters.totalSteps - 1) {
            startImport(allowPkgSelection);
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    const handlePrevious = (): void => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    }

    if (!importCustomerFinancialsActive) {
        return (<></>);
    }

    return (
        <Modal
            className={`flight-modal lender-region control-region-lender new-data-collection-modal`}
            isOpen={true}
            toggle={toggleModal}
            backdrop={'static'}
        >
            <ModalHeader toggle={toggleModal}>
                <div className={'multi-content-header'}>
                    <span>New Data Collection</span>
                    {currentStep !== stepParameters.importStepChooseMode && (
                        <ImportModeSelector
                            linkType={linkMode}
                            selected={false}
                            id={linkMode === LinkMode.shareable ? 'shareable-link-selector' : 'direct-link-selector'}
                            hideSelectionSingleLine={true}
                            containerStyle={{
                                marginLeft: '25px'
                            }}
                        />
                    )}
                </div>
            </ModalHeader>
            <ModalBody>
                <Container fluid className={'new-data-collection-modal-body'}>
                    {errorNoGroupsSelected && (
                        <ConfirmModal
                            msg={'No accounting data is selected for import.'}
                            title={'Error'}
                            modalType={ConfirmModalType.ok}
                            onTerminalButton={() => {
                                setErrorNoGroupsSelected(false);
                                setCurrentStep(stepParameters.totalSteps - 1);
                            }}
                        />
                    )}
                    <Row className={'main-content-container'}>
                        <Col className={'collection-options-container'}>
                            <Row>
                                <Col>
                                    <Stepper
                                        alternativeLabel
                                        activeStep={currentStep}
                                        nonLinear
                                    >
                                        {
                                            importSteps.map((step, index) => {
                                                return (
                                                    <Step
                                                        key={step.key}
                                                    >
                                                        <StepButton
                                                            onClick={() => setCurrentStep(index)}
                                                        >
                                                            {step.label}
                                                        </StepButton>
                                                    </Step>
                                                 );
                                            })
                                        }
                                    </Stepper>
                                </Col>
                            </Row>
                            <Row className={'extra-row-spacing-medium'}>
                                <Col className={'data-collection-import-container'}>
                                    {/* render the current step */ }

                                    {(currentStep < stepParameters.totalSteps) && (currentStep >= 0) && stepRenderers[importSteps[currentStep].renderIndex]()}

                                    {/* Or show an error, this should be purely a stopgap picked up in QA */}

                                    {((currentStep >= stepParameters.totalSteps) || (currentStep < 0)) && (
                                        <p>
                                            Internal error, the current step is out of the valid range
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={'extra-row-spacing-small'}>
                        <Col className={'centered-col'}>
                            <StrongboxTOU className={'modal-terms-of-use'} />
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Tooltip title={'Previous options page'}>
                    <div>
                        <Button
                            className={'btn-thin-horizontal-padding'}
                            color={'primary'}
                            onClick={(e) => {
                                handlePrevious();
                            }}
                            disabled={backButtonDisabled}
                        >
                            <ArrowBackward />
                        </Button>
                    </div>
                </Tooltip>
                <Tooltip title={'Next options page'}>
                    <div>
                        <Button
                            className={'btn-thin-horizontal-padding'}
                            color={'primary'}
                            onClick={(e) => {
                                handleOnSubmit(!directOnly);
                            }}
                        >
                            <ArrowForward />
                        </Button>
                    </div>
                </Tooltip>
                <Button color={'secondary'} onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export const ImportCustomerFinancials = connect<InjectedReduxState, InjectedActionCreators, ImportCustomerFinancialsContainerProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            importCustomerFinancialsActive: GetImportCustomerFinancialsActive(appState),
            shareableLinkParamsActive: GetShareableLinkParamsFlowSwitch(appState),
            importOptions: GetCreationOptions(appState),
            maxYearsInAdditionToFYTD: GetMaxYearsInAdditionToFYTDForCollection(appState),
            numberOfMonthsInDropdown: GetNumMonthsInImportYTDDropdown(appState),
            disableFileUpload: GetDisableAdditionalDocUpload(appState),
            disableProvideUserCopy: GetDisableProvideuserCopy(appState),
            disableBasisOfAccounting: GetDisableBasisOfAccounting(appState),
            disableAnonymizeResults: GetDisableAnonymizeResults(appState),
            allUserOptionsDisabled: GetAllUserOptionsDisabled(appState),
            disableDirectLinking: DirectLinkingDisabled(appState),
            collectionContentGroups: GetCollectionGroupings(appState),
            collectionGroupDescriptions: RetrieveResource(appState, ResourceName.tenantDataCollectionGroupDescriptions),
            collectionGroupDescriptionsExcel: RetrieveResource(appState, ResourceName.tenantDataCollectionGroupDescriptionsExcel),
            noTimeRestrictionForDataCollection: NoTimeRestrictionForDataCollection(appState),
            additionalMessagingSelectAccountingDataPage: RetrieveResource(appState, ResourceName.tenantAdditionalMessagingSelectAccountingDataPage),
            excelImportEnabled: FileTemplateUploadEnabled(appState),
            dynamicTenantSelectionEnabled: GetDynamicTenantSelectionSwitch(appState),
        };

        return result;
    },
    {
        ...ImportFinancialsActionCreators,
        ...NewDataCollectionParametersActionCreators,
    }
)(ImportCustomerFinancialsComponent);
