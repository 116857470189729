import { ApplicationState } from '../index';
import { ModifyWorkspaceUsersState, UIRedirectInfo } from './Reducer';

export function GetAddUsers(state: ApplicationState): boolean {
    return !!(state && state.uiState && state.uiState.showingAddUsers);
}

export function UserCanNavigateHome(state: ApplicationState): boolean {
    return (!!state && !!state.uiState && state.uiState.allowHomeNavigate);
}

export function GetPortalBusyState(state: ApplicationState): boolean {
    return (!!state && !!state.uiState && (state.uiState.working !== 0))
}

export function GetRedirectAfterLogin(state: ApplicationState): UIRedirectInfo | undefined {
    return (state && state.uiState && state.uiState.redirectAfterLogin);
}

export function GetModifyWorkspaceUsersState(state: ApplicationState): ModifyWorkspaceUsersState | undefined {
    return (state && state.uiState && state.uiState.modifyWorkspaceUserState);
}
