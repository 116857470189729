import {
    MappedWorkspace,
    uninitializedPageInfo,
    WorkspaceWithMeta,
    WorkspacePageInfo,
    WorkspacePlusUser
} from './Reducer';
import {
    WorkspacesActions,
    IAddLoadedWorkspace,
    IResetWorkspaceUsers,
    IUpdateLoadedWorkspaceDetails,
    IOverrideUserWorkspaceAccess,
} from './Actions';
import { GetDetailedWorkspaces } from './Selectors';

import { store } from '../../index';

import {
    DeleteWorkspace,
    GetWorkspaceDetails,
    ListWorkspacesWithTextSearchFilter,
    UpdateWorkspaceUsers
} from '../../Services/WorkspaceService';
import {
    DeleteWorkspaceConnection,
    ListWorkspaceConnections
} from '../../Services/WorkspaceConnectionsService';

import {
    EntityDetailResponse,
    EntityResponse,
    UserResponse
} from '../../Models/Api/strongbox.financialportal';

import { LogMessage, LogException, SeverityLevel } from '../../Utils/Logging';

export async function LoadWorkspaces(pageNumber: number, searchFilter: string = ''): Promise<WorkspaceWithMeta[]> {
    store.dispatch({ type: WorkspacesActions.LoadWorkspaces });

    let result: WorkspaceWithMeta[] = [];
    let pageInfo: WorkspacePageInfo = uninitializedPageInfo

    try {
        var response;

        if (!!searchFilter) {
            response = await ListWorkspacesWithTextSearchFilter(searchFilter, pageNumber);
        } else {
            response = await ListWorkspacesWithTextSearchFilter('', pageNumber);
        }

        try {
            const workspaceList: EntityResponse[] = response.pageData;
            result = workspaceList.map(workspace => MappedWorkspace(workspace));

            pageInfo = {
                workspacesPerPage: response.itemsPerPage,
                totalWorkspaces: response.totalItems,
                currentPage: pageNumber,
                searchTerm: searchFilter
            }
        } catch (e) {
            LogException(
                `Unable to map workspace while retrieving list of workspaces`,
                e,
                {
                    pageNumber
                }
            );
            console.error('unexpected error mapping business result');
            console.error(e);
        }
    } catch (reason) {
        LogException(
            `Failure listing workspaces`,
            reason,
            {
                pageNumber
            }
        );
        console.error(reason);
    }

    store.dispatch({
        type: WorkspacesActions.LoadWorkspacesComplete,
        workspaces: result,
        pageInfo 
    });

    return result;
}

export async function LoadWorkspaceDetails(id: string, allUsers: UserResponse[]):
    Promise<
        {
            entityDetails: EntityDetailResponse,
            userInformation: WorkspacePlusUser
        }
        | undefined
    >
{
    LogMessage(
        `LoadingWorkspaceDetails for workspace ${id}`,
        SeverityLevel.Information,
        {
            id
        }
    );

    store.dispatch({
        type: WorkspacesActions.LoadWorkspaceDetails,
        id
    });

    try {
        const result: WorkspacePlusUser = {
            id,
            loading: false,
            users: [],
            initialUserState: []
        }

        const details = await GetWorkspaceDetails(id);

        if (!details) {
            throw new Error(`Unexpected state, not able to retrieve details for workspace id ${id}`);
        }

        // We want full users in our results so we can do interesting things with the result, the
        // access control list just has id's.   Go through each element in accessControlList (id's) 
        // then try to find that id in the list of users that we have.   If we find that id in the list
        // of full user responses added it to result.users
        if (!!details.accessControlList) {
            details.accessControlList.forEach(resourceUser => {
                const thisUser = allUsers.find(existingUser => existingUser.id === resourceUser.principalId);
                if (!!thisUser) {
                    result.users.push(thisUser);
                }
            });
        }

        store.dispatch({
            type: WorkspacesActions.LoadWorkspaceDetailsComplete,
            id,
            userDetails: result,
            workspaceDetails: details,
        });

        return {
            entityDetails: details,
            userInformation: result,
        }
    } catch (exception) {
        LogException(
            `Exception retrieving workspace details for workspace ${id}`,
            exception,
            {
                id
            }
        );
        store.dispatch({
            type: WorkspacesActions.LoadWorkspaceDetailsComplete,
            id,
            details: undefined
        });

        return undefined;
    }
}

export async function WorkspaceDelete(id: string): Promise<void> {
    store.dispatch({
        type: WorkspacesActions.DeleteWorkspace,
        workspaceId: id
    });

    await DeleteWorkspace(id);

    store.dispatch({
        type: WorkspacesActions.DeleteWorkspaceComplete,
        workspaceId: id
    });
}

export function AddLoadedWorkspace(workspace: EntityResponse): IAddLoadedWorkspace {
    return {
        type: WorkspacesActions.AddLoadedWorkspace,
        workspace
    };
}

export async function SaveWorkspaceUsers(id: string) {
    store.dispatch({
        type: WorkspacesActions.SaveWorkspaceUsers,
        id,
    });

    try {
        const workspaces = GetDetailedWorkspaces(store.getState());
        const iWorkspace = workspaces.findIndex(ws => ws.id === id);
        if (iWorkspace === -1) {
            throw new Error(`'Attempting to save workspace users for unloaded workspace ${id}`);
        }

        await UpdateWorkspaceUsers(id, workspaces[iWorkspace].users.map(user => user.id));

        store.dispatch({
            type: WorkspacesActions.SaveWorkspaceUsersComplete,
            id,
        });
    } catch (exception) {
        console.error(`Failed saving workspace users for workspace ${id}`);
        console.error(exception);

        LogException(
            `Failed saving workspace users for workspace ${id}`,
            exception,
            {
                workspaceId: id
            }
        );
        store.dispatch({
            type: WorkspacesActions.SaveWorkspaceUsersComplete,
            id,
        });
    }
}

export async function ChangeWorkspaceUserStatus(id: string, user: UserResponse, include: boolean): Promise<void> {
    store.dispatch({
        type: WorkspacesActions.ChangeWorkspaceUserStatus,
        id,
        user,
        include
    });

    // This is the status we will send in the status complete message which should be whatever they already have. It's 
    // reasonable to assume that the opposite of what it's being changed to is the existing state.  This is used because
    // the dispatch above sets the state of the workspace to working and the dispatch below sets it to not working.  
    //
    // Current implementation doesn't do anything that requires this, i.e. it executes really fast, but if change is needed,
    // that will be useful.
    let errorStatus = !include;

    try {
        const workspaces = GetDetailedWorkspaces(store.getState());
        const iWorkspace = workspaces.findIndex(ws => ws.id === id);
        if (iWorkspace === -1) {
            throw new Error(`Unexpected error updating user status in a workspace ${id} userId: ${user.id}`);
        }
        store.dispatch({
            type: WorkspacesActions.ChangeWorkspaceUserStatusComplete,
            id,
            user,
            include
        });
    } catch (exception) {
        console.error(`Error updating state of user for a workspace: ${id} user id ${user.id}`);

        LogException(
            `Error updating state of user for a workspace: ${id} user id ${user.id}`,
            new Error(`Error updating state of user for a workspace: ${id} user id ${user.id}`),
            {
                workspaceId: id,
                userId: user.id
            }
        );

        store.dispatch({
            type: WorkspacesActions.ChangeWorkspaceUserStatusComplete,
            id,
            user,
            include: errorStatus
        });
    }
}

/**
 * Removes a number of users from a workspace
 * @param id: Id of the workspace being changed
 * @param exclude: Excludes user objects from modification if it's provided.  This does not mean they are
 * turned off, it means this operation has no effect on them.
 */
export async function BulkRemoveAllWorkspaceUsers(id: string, exclude?: UserResponse[]): Promise<void> {
    store.dispatch({
        type: WorkspacesActions.BulkRemoveAllWorkspaceUsers,
        id,
        exclude
    });
    store.dispatch({
        type: WorkspacesActions.BulkRemoveAllWorkspaceUsersComplete,
        id,
        exclude
    });
}

export async function BulkAddWorkspaceUsers(id: string, users: UserResponse[]): Promise<void> {
    store.dispatch({
        type: WorkspacesActions.BulkAddWorkspaceUsers,
        id,
        users
    });
    store.dispatch({
        type: WorkspacesActions.BulkAddWorkspaceUsersComplete,
        id,
        users
    });
}

export function ResetWorkspaceUsers(id: string): IResetWorkspaceUsers {
    return {
        type: WorkspacesActions.ResetWorkspaceUsers,
        id
    };
}

export function UpdateLoadedWorkspaceDetails(id: string, newName: string, newEngagementCode?: string): IUpdateLoadedWorkspaceDetails {
    return {
        type: WorkspacesActions.UpdateLoadedWorkspaceDetails,
        id,
        newName,
        newEngagementCode
    };
}

/**
 * Loads all connections for a workspace
 * @param workspaceId id of the workspace to load
 * */

export async function LoadWorkspaceConnections(id: string): Promise<void> {
    store.dispatch({
        type: WorkspacesActions.LoadWorkspaceConnections,
        id,
    });
    try {
        const connections = await ListWorkspaceConnections(id);

        store.dispatch({
            type: WorkspacesActions.LoadWorkspaceConnectionsComplete,
            id,
            connections,
        });
    } catch (exception) {
        LogException(
            `Failed loading connections for workspace ${id}`,
            exception,
            {
                id
            }
        );
        store.dispatch({
            type: WorkspacesActions.LoadWorkspaceConnectionsComplete,
            id,
            connections: undefined
        });
    }
}

/**
 * Delete a workspace connection
 * @param workspaceId id of the workspace from which to delete the connection
 * @param connectionId is the id of the connection to delete
 * */

export async function DeleteConnectionFromWorkspace(id: string, connectionId: string): Promise<boolean> {
    store.dispatch({
        type: WorkspacesActions.DeleteWorkspaceConnection,
        id,
        connectionId,
    });
    try {
        await DeleteWorkspaceConnection(id, connectionId);

        store.dispatch({
            type: WorkspacesActions.DeleteWorkspaceConnectionComplete,
            id,
            connectionId,
            success: true,
        });

        return true;
    } catch (exception) {
        LogException(
            `Failed loading connections for workspace ${id}`,
            exception,
            {
                id
            }
        );
        store.dispatch({
            type: WorkspacesActions.DeleteWorkspaceConnectionComplete,
            id,
            connectionId,
            success: false,
        });

        return false;
    }
}

/**
 * Overrides whether a user has the permission to access a workspace in the redux store only.  
 * This has no effect on the database.  There's a lag in settings changes taking effect and this
 * allows for immediately locking the UI.  Any changes made here will be overridden by a refresh.
 * 
 * @param id workspace id
 * @param userId id of the user to change access for
 * @param allow allow or deny access
 */
export function OverrideUserWorkspaceAccess(id: string, userId: string, allow: boolean): IOverrideUserWorkspaceAccess {
    return {
        type: WorkspacesActions.OverrideWorkspaceAccessibilityForUser,
        id,
        userId,
        allow
    };
}

export const actionCreators = {
    AddLoadedWorkspace: AddLoadedWorkspace,
    ResetWorkspaceUsers: ResetWorkspaceUsers,
    UpdateLoadedWorkspaceDetails: UpdateLoadedWorkspaceDetails,
    OverrideUserWorkspaceAccess: OverrideUserWorkspaceAccess,
};
