import {
    AppThunkAction
} from '../index';

import {
    SubmissionActions,
    KnownAction,
    IUpdateMetadata,
    ISubmissionPendingInterventionDeleted,
} from './Actions';

import {
    SubmissionPortalStatus,
    Submission,
    SubmissionSearchResponse,
} from '../../Models/Api/strongbox.financialportal';
import { AccountingPackage } from '../../Models/Api/AccountingPackages';

import { LogMessage, LogException } from '../../Utils/Logging';

import {
    GetSubmissionDetail,
    SetSubmissionPortalStatus,
    ListSubmissionsPendingMoreLenderInput,
} from '../../Services/SubmissionService';

/**
 * Load a submission 
 * @param workspaceId identifies the workspace to which the submission belongs.
 * @param submissionId identifies the submission
 * @param metadata additional data to store with the entry.  Meaning of this is defined by the consumer
 */
const LoadSubmission = (
    workspaceId: string,
    submissionId: string,
    metadata?: Map<string, any>
): AppThunkAction<Submission | undefined, unknown, KnownAction> =>
    async (dispatch, getState): Promise<Submission | undefined> => {
        try {
            LogMessage(`LoadSubmission, submissionId=${submissionId}`);

            dispatch({
                type: SubmissionActions.LoadSubmission,
                workspaceId,
                submissionId,
                metadata,
            });

            const submissionDetails = await GetSubmissionDetail(workspaceId, submissionId);
            const submission: Submission = {
                ...submissionDetails,
            }

            dispatch({
                type: SubmissionActions.LoadSubmissionComplete,
                workspaceId,
                submissionId,
                submission,
                metadata,
            })

            return submission;
        } catch (e) {
            const errorMsg = 'LoadSubmission action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e, {
                submissionId
            });

            dispatch({
                type: SubmissionActions.LoadSubmissionComplete,
                workspaceId,
                submissionId,
                msg: 'Failed loading submission',
                exceptionMsg: e,
                metadata,
            });

            return undefined;
        }
    }

function UpdateSubmissionMetadata(workspaceId: string, submissionId: string, metadata: Map<string, any>): IUpdateMetadata {
    return {
        type: SubmissionActions.UpdateSubmissionMetadata,
        workspaceId,
        submissionId,
        metadata
    };
}


/**
 * Update a submission portal status
 * @param workspaceId identifies the workspace to which the submission belongs.
 * @param submissionId identifies the submission
 * @param status new status for the submission
 */
const UpdateSubmissionStatus = (
    workspaceId: string,
    submissionId: string,
    status: SubmissionPortalStatus,
    accountingPackage: AccountingPackage
): AppThunkAction<Submission | undefined, unknown, KnownAction> =>
    async (dispatch, getState): Promise<Submission | undefined> => {
        try {
            LogMessage(`UpdateSubmissionStatus, workspaceId=${workspaceId} submissionId=${submissionId}`);
            
            dispatch({
                type: SubmissionActions.UpdateSubmissionStatus,
                workspaceId,
                submissionId,
                status,
            });

            const submission = await SetSubmissionPortalStatus(workspaceId, submissionId, status, accountingPackage.toLowerCase());

            dispatch({
                type: SubmissionActions.UpdateSubmissionStatusComplete,
                workspaceId,
                submissionId,
                datasourceNameId: accountingPackage,
                status,
                submission,
            })

            return submission;
        } catch (e) {
            const errorMsg = 'UpdateSubmissionStatus action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e, {
                workspaceId,
                submissionId,
                status,
            });

            dispatch({
                type: SubmissionActions.UpdateSubmissionStatusComplete,
                workspaceId,
                submissionId,
                datasourceNameId: accountingPackage,
                status,
                exceptionMsg: 'Unable to update submission status',
            })

            return undefined;
        }
    }

/**
 * Load submissions where connections did not complete in borrower flow because additional action is required from the
 * lender to complete the connection
 */
const LoadSubmissionsPendingLenderIntervention = (
    onComplete?: () => void
): AppThunkAction<SubmissionSearchResponse | undefined, unknown, KnownAction> =>
    async (dispatch, getState): Promise<SubmissionSearchResponse | undefined> => {
        try {
            LogMessage(`LoadSubmissionsPendingLenderIntervention`);

            dispatch({
                type: SubmissionActions.LoadSubmissionsPendingIntervention,
            });

            const submissionsPendingInput = await ListSubmissionsPendingMoreLenderInput(false);

            dispatch({
                type: SubmissionActions.LoadSubmissionsPendingInterventionComplete,
                submissionInfo: submissionsPendingInput,
            })

            onComplete && onComplete();

            return submissionsPendingInput;
        } catch (e) {
            const errorMsg = 'LoadSubmissionsPendingLenderIntervention action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch({
                type: SubmissionActions.LoadSubmissionsPendingInterventionComplete,
                exceptionMsg: errorMsg,
            });
            
            onComplete && onComplete();

            return undefined;
        }
    }

function SubmissionPendingInterventionDeleted(workspaceId: string, submissionId: string): ISubmissionPendingInterventionDeleted {
    return {
        type: SubmissionActions.SubmissionPendingInterventionDeleted,
        workspaceId,
        submissionId,
    };
}

export const actionCreators = {
    LoadSubmission,
    UpdateSubmissionMetadata,
    UpdateSubmissionStatus,
    LoadSubmissionsPendingLenderIntervention,
    SubmissionPendingInterventionDeleted,
};
