import { ApplicationState } from '../index';

import { SubmissionSearchResult } from '../../Models/Api/strongbox.financialportal';
import { SubmissionRecord } from './Reducer';

export function GetSubmission(state: ApplicationState, submissionId: string): SubmissionRecord | undefined {
    if (!(state && state.submission && state.submission.submissions.has(submissionId))) {
        return undefined;
    }
    return state.submission.submissions.get(submissionId);
}

export function GetSubmissionsPendingLenderIntervention(state: ApplicationState): SubmissionSearchResult[] {
    if (!(state && state.submission)) {
        return [];
    }
    return state.submission.submissionsPendingIntervention?.submissions || [];
}
