import { ActiveSubmissionDetails, DownloadableFileState, SubmissionSearchResultWithDate, SubmissionHistoryType } from './Reducer';

import { SubmissionHistoryRange, SubmissionHistoryRangeSelectionType } from '../../Utils/SubmissionHistoryRange';

export enum SubmissionDetailsActions {
    SetActiveSubmission = 'SUBMISSIONDETAILS/SetActiveSubmission',
    SetCurrentScrollPos = 'SUBMISSIONDETAILS/SetCurrentScrollPos',
    SetCurrentResults = 'SUBMISSIONDETAILS/SetCurrentResults',
    SetCurrentHistoryRange = 'SUBMISSIONDETAILS/SetCurrentHistoryRange',
    SetCurrentRangeType = 'SUBMISSIONDETAILS/SetCurrentRangeType',
    SetSortColumn = 'SUBMISSIONDETAILS/SetSortColumn',
    SetSortOrder = 'SUBMISSIONDETAILS/SetSortOrder',
    SetStatusFilter = 'SUBMISSIONDETAILS/SetStatusFilter',
    SetSourceFilter = 'SUBMISSIONDETAILS/SetSourceFilter',
    TempSubmissionDetailsStateClear = 'TEMPSUBMISSIONDETAILSSTATE/Clear',
    TempSubmissionDetailsStateSetSelectedFiles = 'TEMPSUBMISSIONDETAILSSTATE/SetSelectedFiles',
    TempSubmissionDetailsStateToggleSelectedFile = 'TEMPSUBMISSIONDETAILSSTATE/ToggleSelected',
    UpdateWorkspaceNameInResults = 'SUBMISSIONDETAILS/UpdateWorkspaceName',
    SubmissionDeleted = 'SUBMISSIONDETAILS/SubmissionDeleted',
    SubmissionRestored = 'SUBMISSIONDETAILS/SubmissionRestored',
}

export interface ISetActiveSubmission {
    readonly type: SubmissionDetailsActions.SetActiveSubmission;
    readonly submissionDetails?: ActiveSubmissionDetails;
}

export interface ISetCurrentScrollPos {
    readonly type: SubmissionDetailsActions.SetCurrentScrollPos;
    readonly scrollPos: number;
}

export interface ISetCurrentResults {
    readonly type: SubmissionDetailsActions.SetCurrentResults;
    readonly results: SubmissionSearchResultWithDate[];
    readonly workspaceId?: string;
}

export interface ISetCurrentHistoryRange {
    readonly type: SubmissionDetailsActions.SetCurrentHistoryRange;
    readonly currentRange: SubmissionHistoryRange;
    readonly historyType: SubmissionHistoryType;
}

export interface ISetCurrentRangeType {
    readonly type: SubmissionDetailsActions.SetCurrentRangeType;
    readonly rangeType: SubmissionHistoryRangeSelectionType;
    readonly historyType: SubmissionHistoryType;
}

export interface ISetSortColumn {
    readonly type: SubmissionDetailsActions.SetSortColumn;
    readonly column: number;
}

export interface ISetSortOrder {
    readonly type: SubmissionDetailsActions.SetSortOrder;
    readonly order: string;
}

export interface ISetStatusFilter {
    readonly type: SubmissionDetailsActions.SetStatusFilter;
    readonly id: string;
    readonly state: boolean;
}

export interface ISetSourceFilter {
    readonly type: SubmissionDetailsActions.SetSourceFilter;
    readonly id: string;
    readonly state: boolean;
}

export interface IUpdateWorkspaceName {
    readonly type: SubmissionDetailsActions.UpdateWorkspaceNameInResults;
    readonly id: string;
    readonly newName: string;
}

export interface ITempSubmissionDetailsStateClear {
    readonly type: SubmissionDetailsActions.TempSubmissionDetailsStateClear;
}

export interface ITempSubmissionDetailsStateSetSelectedFiles {
    readonly type: SubmissionDetailsActions.TempSubmissionDetailsStateSetSelectedFiles;
    readonly submissionId: string;
    readonly files: DownloadableFileState[];
    readonly preserveSelectionState?: boolean;
}

export interface ITempSubmissionDetailsStateToggleSelectedFile {
    readonly type: SubmissionDetailsActions.TempSubmissionDetailsStateToggleSelectedFile;
    readonly submissionId: string;
    readonly fileId: string;
}

export interface ISubmissionDeleted {
    readonly type: SubmissionDetailsActions.SubmissionDeleted;
    readonly submissionId: string;
    readonly workspaceId: string;
}

export interface ISubmissionRestored {
    readonly type: SubmissionDetailsActions.SubmissionRestored;
    readonly submissionId: string;
    readonly workspaceId: string;
}

export type KnownAction =
    ISetActiveSubmission |
    ISetCurrentScrollPos |
    ISetCurrentResults |
    ISetCurrentHistoryRange |
    ISetCurrentRangeType |
    ISetSortColumn | 
    ISetSortOrder |
    ISetStatusFilter | 
    ISetSourceFilter |
    IUpdateWorkspaceName |
    ITempSubmissionDetailsStateClear |
    ITempSubmissionDetailsStateSetSelectedFiles |
    ITempSubmissionDetailsStateToggleSelectedFile |
    ISubmissionDeleted |
    ISubmissionRestored;
