import * as React from 'react';

import { FinancialRecordStatus } from '../../Models/Api/strongbox.financialportal';

import {
    Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlined';

import { GetStdThemeColors } from '../../Utils/Style';
import { BrandConfig } from '../../Store/Tenant';
import { LinkMode } from '../../Store/ImportFinancials';

import { deleteOrErrorButtonColor } from '../../Utils/Constants';

import { SubmittedByIcon } from '../SubmittedByIcon/SubmittedByIcon';

export type SubmissionStatusSummary = {
    statusText: string,
    showLink: boolean,
    key: 'deleted' | 'empty' | 'valid' | 'unknown' | 'error',
};

const iconStyle = {
    marginLeft: '3px',
}

export function GetSubmissionStatus(financialRecordStatus: FinancialRecordStatus | undefined, financialRecordId?: string, financialRecordCreated?: string): SubmissionStatusSummary {
    switch (financialRecordStatus) {
        case 'Deleted':
            return {
                key: 'deleted',
                statusText: 'Deleted',
                showLink: true
            };
        case 'Unknown':
            return {
                key: 'unknown',
                statusText: 'Unknown',
                showLink: true
            };
        case 'ImportError':
            return {
                key: 'error',
                statusText: 'Error occurred during data collection',
                showLink: true
            };
        default:
            if ((!financialRecordId) ||
                (!financialRecordCreated)) {
                return {
                    key: 'empty',
                    statusText: 'No data available',
                    showLink: true
                };
            }
            return {
                key: 'valid',
                statusText: '',
                showLink: true
            };
    }
}

export function GetStatusContent(brandConfig: BrandConfig, submissionId: string, submissionStatus: SubmissionStatusSummary): React.ReactElement {
    const colors = GetStdThemeColors(brandConfig);

    switch (submissionStatus.key) {
        case 'deleted': {
            return (
                <Tooltip title={'Deleted'}>
                    <DeleteIcon
                        color={'primary'}
                        className={'basic-list-icon'}
                        id={`deleteIcon${submissionId}`}
                        onClick={() => { }}
                        style={iconStyle}
                    />
                </Tooltip>
            );
        }
        case 'error': {
            return (
                <Tooltip title={'Error during data collection'}>
                    <ErrorIcon
                        color={'primary'}
                        className={'basic-list-icon'}
                        id={`errorIcon${submissionId}`}
                        onClick={() => { }}
                        style={{
                            ...iconStyle,
                            color: deleteOrErrorButtonColor,
                        }}
                    />
                </Tooltip>
            );
        }
        case 'empty': {
            return (
                <Tooltip title={'No financial data available'}>
                    <span
                        className={'submissions-icon submissions-icon-no-data'}
                        id={`empty${submissionId}`}
                        style={{
                            ...iconStyle,
                            background: colors.primaryColor,
                        }}
                    />
                </Tooltip>

            );
        }
        case 'unknown': {
            return (
                <Tooltip title={'Unknown'}>
                    <span
                        className={'submissions-icon submission-icon-status-unknown'}
                        id={`unknown${submissionId}`}
                        style={{
                            ...iconStyle,
                            background: colors.primaryColor,
                        }}
                    />
                </Tooltip>
            );
        }
        case 'valid': {
            return (
                <Tooltip title={'Financial data available'}>
                    <span
                        className={'submissions-icon submissions-icon-data-available'}
                        id={`dataAvailable${submissionId}`}
                        style={{
                            ...iconStyle,
                            background: colors.primaryColor,
                        }}
                    />
                </Tooltip>
            );
        }
    }
    return (<></>);
}


export function GetSubmissionStatusIconColumn(
    brandConfig: BrandConfig, 
    linkMode: LinkMode,
    submissionId: string, 
    financialRecordStatus: FinancialRecordStatus | undefined, 
    financialRecordId?: string, 
    financialRecordCreated?: string
): React.ReactElement {
    const submissionStatus = GetSubmissionStatus(financialRecordStatus, financialRecordId, financialRecordCreated);
    const statusContent = GetStatusContent(brandConfig, submissionId, submissionStatus);

    return (
        <div className={'table-icon-column'}>
            <SubmittedByIcon
                linkType={linkMode}
                iconId={submissionId}
                iconStyle={iconStyle}
            />
            {statusContent}
        </div>
    );
}

export function CompareCreationTime(d1?: Date, d2?: Date): number {
    if (!(d1 && d2)) {
        // If they're both undefined they're equal.  Else if d1 is undefined it's less than d2
        return (d1 === d2) ? 0 : (!d1 ? -1 : 1);
    }

    return (d1 < d2) ? -1 : ((d1 === d2) ? 0 : 1);
}

export function CompareStringsDefaultToCreationTime(s1: string | undefined, s2: string | undefined, d1?: Date, d2?: Date): number {
    const compareS1 = s1?.toLowerCase() || '';
    const compareS2 = s2?.toLowerCase() || '';

    if (compareS1 === compareS2) {
        return CompareCreationTime(d1, d2);
    }
    return (compareS1 < compareS2) ? -1 : 1;
}

export function GetWorkspaceDisplayName(deleted: boolean, name?: string): string {
    return `${name}${deleted ? ' (deleted)' : ''}`
}

