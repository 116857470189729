import { fetchWrapper, FetchMethod } from './FetchWrapper';

import { AccountingPackage } from '../Models/Api/AccountingPackages';

import { BasisOfAccountingPreference, LenderConnectionOptions, } from '@finagraph/strongbox-finconnect-react';
import {
    PortalDefaultBasisOfAccounting,
    PortalCreateSubmissionRequestOptions
} from '../Models/PortalCreateSubmissionRequestOptions';
import { knownAccountingGroupIds } from '../Models/AccountingCollectionGroup';

import { LogException, LogMessage, SeverityLevel } from './Logging';

import { IDelegatedAccessToken } from '@finagraph/strongbox-nexus-client';

export function AccountingPackageFriendlyNameFromStringDescriptor(accountingPackage: string): string {
    const lowCaseAccountingPackage = accountingPackage.toLowerCase();

    if (lowCaseAccountingPackage === AccountingPackage.QuickBooksOnline.toLowerCase()) {
        return 'QuickBooks Online';
    } else if (lowCaseAccountingPackage === AccountingPackage.Xero.toLowerCase()) {
        return 'Xero';
    } else if (lowCaseAccountingPackage === AccountingPackage.QuickBooksDesktop.toLowerCase()) {
        return 'QuickBooks Desktop';
    } else if (lowCaseAccountingPackage === AccountingPackage.SageIntacct.toLowerCase()) {
        return 'Sage Intacct';
    } else if (lowCaseAccountingPackage === AccountingPackage.Example.toLowerCase()) {
        return 'DEX';
    } else if (lowCaseAccountingPackage === AccountingPackage.FreeAgent.toLowerCase()) {
        return 'FreeAgent';
    } else if (lowCaseAccountingPackage === AccountingPackage.NetSuite.toLowerCase()) {
        return 'NetSuite';
    } else if (lowCaseAccountingPackage === AccountingPackage.MYOBBusiness.toLowerCase()) {
        return 'MYOB Business';
    } else if (lowCaseAccountingPackage === AccountingPackage.BusinessCentral.toLowerCase()) {
        return 'Business Central';
    } else if (lowCaseAccountingPackage === AccountingPackage.FileUpload.toLowerCase()) {
        return 'Excel File Upload';
    } else {
        // There are valid cases where the accounting package can empty.  If there was an error collecting
        // or the collection isn't complete, you see it all the time in the portal.  Logging this message
        // for an empty string as opposed to the case where it should never happen is super spammy.
        if (!!accountingPackage.trim()) {
            LogMessage(
                `Failed getting accounting package friendly name descriptor ${accountingPackage}, returning raw name`,
                SeverityLevel.Error,
                {
                    accountingPackage
                }
            );
        }
        return accountingPackage;
    }
}

export function GetAccountingImageClass(datasourceNameId: string): string {
    const lowCaseAccountingPackage = datasourceNameId.toLowerCase();

    if (lowCaseAccountingPackage === AccountingPackage.QuickBooksOnline.toLowerCase()) {
        return 'qbo-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.Xero.toLowerCase()) {
        return 'xero-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.QuickBooksDesktop.toLowerCase()) {
        return 'qbo-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.SageIntacct.toLowerCase()) {
        return 'sage-intacct-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.Example.toLowerCase()) {
        return 'example-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.FreeAgent.toLowerCase()) {
        return 'free-agent-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.NetSuite.toLowerCase()) {
        return 'netsuite-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.BusinessCentral.toLowerCase()) {
        return 'businesscentral-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.MYOBBusiness.toLowerCase()) {
        return 'myob-icon';
    } else if (lowCaseAccountingPackage === AccountingPackage.FileUpload.toLowerCase()) {
        return 'excel-upload-icon';
    } else {
        // There are valid cases where the accounting package can empty.  If there was an error collecting
        // or the collection isn't complete, you see it all the time in the portal.  Logging this message
        // for an empty string as opposed to the case where it should never happen is super spammy.
        if (!!lowCaseAccountingPackage.trim()) {
            LogMessage(
                `Failed getting accounting package image class ${datasourceNameId}, returning empty string`,
                SeverityLevel.Error,
                {
                    datasourceNameId
                }
            );
        }
        return '';
    }
}

export async function getDelegatedToken(eid: string): Promise<IDelegatedAccessToken> {
    try {
        // jsonHeaders true, requireAuth false
        let url = `/api/Workspaces/${eid}/DelegatedAccessTokens`;

        LogMessage(
            `getDelegatedToken invoked`,
            SeverityLevel.Information,
            {
                workspaceId: eid,
            }
        );        

        const response = await fetchWrapper(
            url,
            {
                method: FetchMethod.Post
            },
            true,
            false
        );

        LogMessage(
            `getDelegatedToken response received`,
            SeverityLevel.Information,
            {
                workspaceId: eid,
                statusCode: response.status
            }
        );     

        var authData: IDelegatedAccessToken = await response.json();
        if (authData) {
            LogMessage(
                `getDelegatedToken response read successfully`,
                SeverityLevel.Information,
                {
                    workspaceId: eid,
                    statusCode: response.status,
                }
            );  
        } else {
            throw new Error('UnexpectedApiFailure');
        }

        return authData;
    } catch (e) {

        LogException(
            `failure processing getDelegatedToken request/response`,
            e,
        );
                
        throw e;
    }
}

export function GetFinancialsImportOptions(src: PortalCreateSubmissionRequestOptions): LenderConnectionOptions {
    let mostRecentDate = undefined;

    if ((src.mostRecentPeriodYear) &&
        (src.mostRecentPeriodMonth !== undefined) &&
        (src.mostRecentPeriodDay !== undefined) &&
        (src.mostRecentPeriodMonth >= 0)) {

        mostRecentDate = {
            month: src.mostRecentPeriodMonth,
            year: src.mostRecentPeriodYear,
            day: src.mostRecentPeriodDay,
        }
    }

    const basisOfAccountingPreference: BasisOfAccountingPreference = src.basisOfAccountingPreference || PortalDefaultBasisOfAccounting;

    const txns = src.accountingCollectionGroups.get(knownAccountingGroupIds.transactions);
    const financialStatements = src.accountingCollectionGroups.get(knownAccountingGroupIds.financialStatements);
    const payables = src.accountingCollectionGroups.get(knownAccountingGroupIds.accountsPayable);
    const receivables = src.accountingCollectionGroups.get(knownAccountingGroupIds.accountsReceivable);

    return {
        mostRecentDate,
        anonymizeCustomersAndVendors: src.anonymizeCustomersAndVendors,
        provideUserCopy: src.provideUserCopy,
        financialStatementsPeriod: !!financialStatements?.collect ? 
            {
                reportingPeriod: 'FiscalYears',
                numberOfPeriods: src.fullFiscalYearsToCollect + 1,
                basisOfAccountingPreference,
            } :
            undefined,
        transactionsPeriod: !!txns?.collect ?
            {
                reportingPeriod: 'FiscalYears',
                numberOfPeriods: src.fullFiscalYearsToCollect + 1,
                basisOfAccountingPreference,
            } :
            undefined,
        receivablesPeriod: !!receivables?.collect ?
            {
                reportingPeriod: 'FiscalYears',
                numberOfPeriods: src.fullFiscalYearsToCollect + 1,
            } :
            undefined,
        payablesPeriod: !!payables?.collect ?
            {
                reportingPeriod: 'FiscalYears',
                numberOfPeriods: src.fullFiscalYearsToCollect + 1,
            } :
            undefined,
    };
}
