import { ApplicationState } from '../index';

import {
    ActiveSubmissionDetails,
    DownloadableFileState,
    SubmissionFilter,
    SubmissionSearchResultWithDate,
    statusFilterComplete,
    statusFilterIncomplete,
    statusFilterDeleted,
    sourceFilterShareable,
    sourceFilterDirect,
    sourceFilterAnonymous,
    SubmissionHistoryType
} from './Reducer';
import { currentMonthRange, GetCurrentMonthSubmissionHistoryRange, SubmissionHistoryRange, SubmissionHistoryRangeSelectionType } from '../../Utils/SubmissionHistoryRange';

export function GetActiveSubmissions(state: ApplicationState): ActiveSubmissionDetails[] {
    return (state && state.submissionHistory && state.submissionHistory.details) || [];
}

export function GetCurrentScrollPos(state: ApplicationState): number {
    if (!!state && !!state.submissionHistory) {
        return state.submissionHistory.currentScrollPos;
    }
    return 0;
}

// Gets the current submissions.  If deletedSubmissions is true and provided, it will just retrieve those
// that are deleted.  If it's not provided or false, only non-deleted submissions are returned.
export function GetCurrentResults(state: ApplicationState, includeDeleted: boolean): SubmissionSearchResultWithDate[] {
    if (!!state && !!state.submissionHistory) {
        return state.submissionHistory.currentSearchResults.filter(submissionResult => {
            if (!includeDeleted && !!submissionResult.ssr.submission.deleted) {
                return false;
            }

            const sourceFilterId = !submissionResult.ssr.submissionRequest ? sourceFilterAnonymous :
                submissionResult.ssr.submissionRequest.hasShareableLink ? sourceFilterShareable : sourceFilterDirect;

            const submission = submissionResult.ssr.submission;

            const recordStatus = submission.financialRecordStatus;

            const statusFilterId =
                recordStatus === 'Deleted' ? statusFilterDeleted :
                recordStatus !== 'Created' ? statusFilterIncomplete :
                !(submission.financialRecordId && submission.financialRecordCreated) ? statusFilterIncomplete : statusFilterComplete;

            return state.submissionHistory.statusFilters.find(filter => filter.id === statusFilterId && filter.on) &&
                state.submissionHistory.sourceFilters.find(filter => filter.id === sourceFilterId && filter.on);
        });
    }
    return [];
}

export function GetCurrentResultsWorkspaceId(state: ApplicationState): string | undefined {
    if (!!state && !!state.submissionHistory) {
        return state.submissionHistory.workspaceIdOfCurrentSearchResults;
    }
    return undefined;
}

export function GetUnfilteredCurrentResults(state: ApplicationState): SubmissionSearchResultWithDate[] {
    if (!!state && !!state.submissionHistory) {
        return state.submissionHistory.currentSearchResults;
    }
    return [];
}

export function GetCurrentRangeSelectionType(state: ApplicationState, historyType: SubmissionHistoryType): SubmissionHistoryRangeSelectionType {
    if (!!state && !!state.submissionHistory) {
        switch (historyType) {
            case 'global':
                return state.submissionHistory.globalRangeParameters.selectionType;
            case 'workspace':
                return state.submissionHistory.workspaceRangeParameters.selectionType;
            default:
                console.error(`Invalid history type seen in SubmissionHistory::GetCurrentRangeSelectionType ${historyType}`);
        }
    }
    return currentMonthRange;
}

export function GetCurrentRange(state: ApplicationState, historyType: SubmissionHistoryType): SubmissionHistoryRange {
    if (!!state && !!state.submissionHistory) {
        switch (historyType) {
            case 'global':
                return state.submissionHistory.globalRangeParameters.range;
            case 'workspace':
                return state.submissionHistory.workspaceRangeParameters.range;
            default:
                console.error(`Invalid history type seen in SubmissionHistory::GetCurrentRange ${historyType}`);
        }
    }
    return GetCurrentMonthSubmissionHistoryRange();
}

export function GetSortOrder(state: ApplicationState): string {
    if (!!state && !!state.submissionHistory) {
        return state.submissionHistory.sortOrder;
    }
    return 'descending';
}

export function GetSortColumn(state: ApplicationState): number {
    if (!!state && !!state.submissionHistory) {
        return state.submissionHistory.sortColumn;
    }
    return 1;
}

export function GetSourceFilters(state: ApplicationState): SubmissionFilter[] {
    return (state && state.submissionHistory && state.submissionHistory.sourceFilters) || [];
}

export function GetStatusFilters(state: ApplicationState): SubmissionFilter[] {
    return (state && state.submissionHistory && state.submissionHistory.statusFilters) || [];
}

export function GetTempSubmissionDetailsDownloads(state: ApplicationState, submissionId: string): DownloadableFileState[] {
    if (!(state && state.submissionHistory)) {
        return [];
    }
    const submissionState = state.submissionHistory.temporarySubmissionState.find(subState => subState.submissionId === submissionId);
    if (!submissionState) {
        return [];
    }
    return submissionState.downloadableFiles;
}
