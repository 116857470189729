import {
    EntityAccessResponse,
    EntityDetailResponse,
    EntitySafeDetailResponse,
    EntitiesFilter,
    GetsertEmailEntityRequest,
    PaginatedEntitiesResponse,
    QueryEntitiesRequest,
    QueryableEntityProperties,
    Operation,
    UpsertEntityRequest,
    UserCapability,
    UpdateEntityUsersRequest,
    EntityIdResponse
} from '../Models/Api/strongbox.financialportal';
import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import { uuidv4 } from '@finagraph/strongbox-nexus-client';

import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';

import {
    WorkspaceWithMeta,
} from '../Store/Workspaces';

const PAGE_SIZE = 25;

/**
 * Creates a new workspace entity.   An id is created and submitted to the back end using the name provided
 * 
 * @param workspaceName   Name of the workspace
 * @result id of the workspace.  Will be undefined if an error occurs.
 */

export async function UpsertWorkspaceEntity(
    workspaceName: string,
    workspaceEngagementCode?: string
): Promise<string | undefined> {
    const id = uuidv4();

    const url = `/api/Entities/${id}`;

    const rightNow = new Date().toISOString();

    const requestBody: UpsertEntityRequest = {
        displayName: workspaceName,
        id: id,
        lastActivityTimestamp: rightNow,
        engagementCode: workspaceEngagementCode
    }

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Put,
                body: JSON.stringify(requestBody),
            },
            true,
            true
        );

        if (!res.ok) {
            console.error('Failure inserting new workspace into database');
            console.error(`Attempted id ${id}, workspace name ${workspaceName}`)
            console.error(res.statusText);

            return undefined;
        }

        return id;
    } catch (error) {
        console.error('Failure inserting new workspace into database');
        console.error(`Attempted id ${id}, workspace name ${workspaceName}`)
        console.error(error);

        return undefined;
    }
}

/**
 * Gets or inserts a new workspace entity.  
 *
 * @param workspaceName   Name of the workspace
 * @param primaryEmail   Primary email contact for the workspace.
 *  
 * @result id of the workspace.  Will be undefined if an error occurs.
 */

export async function GetsertWorkspaceEntity(
    workspaceName: string,
    primaryEmail: string
): Promise<string | undefined> {
    const request: GetsertEmailEntityRequest = {
        displayName: workspaceName,
        primaryEmail,
    }

    const url = `/api/Entities`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(request),
            },
            true,
            true,
        )

        if (!res.ok) {
            console.error('Failure getserting new workspace into database');
            console.error(`Attempted workspace name ${workspaceName}`)
            console.error(res.statusText);

            return undefined;
        }

        const responseObject: EntityIdResponse = await res.json();

        return responseObject.id;
    } catch (error) {
        console.error('Failure getserting new workspace into database');
        console.error(`Attempted workspace name ${workspaceName}`)
        console.error(error);

        return undefined;
    }
}

/**
 * Helper to convert searchText input from user to an API filter on entities.
 * @param property The text property we are filtering using searchText.
 * @param searchText The text used to search.
 */
function CreateTextSearchFilter(property: QueryableEntityProperties, searchText: string): EntitiesFilter {

    if (!searchText) {
        throw new Error('Unable to create a search filter from empty searchText.');
    } else if (searchText.length < 3) {
        return {
            operation: 'StartsWith',
            comparand: searchText,
            property: property,
        };
    } else {
        return {
            operation: 'Contains',
            comparand: searchText,
            property: property,
        };
    }
}

/**
 * Query workspace entities. Returns a single page of data using offset-based pagination.
 * Be aware that page drift may occur for most invocations. The only way to get results
 * that will not suffer from page drift are to order by EntityResponse.Number ascending without passing any filters.
 * 
 * @param request  The request to query workspaces.
 * @throws Error If server returns error or if error sending request.
 */

async function QueryWorkspaces(request: QueryEntitiesRequest): Promise<PaginatedEntitiesResponse> {
    LogMessage(
        'QueryWorkspaces',
        SeverityLevel.Information
    );

    try {
        const url = `/api/Entities/query`;

        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(request),
            },
            true,
            true,
        );

        if (res.ok) {
            return res.json();
        } else {
            LogException(
                'QueryWorkspaces failed',
                new Error(res.statusText)
            );

            if (res.bodyUsed) {
                console.error(res.json());
            }
            throw new Error(`Error querying entities. Status code was ${res.status}.`);
        }
    } catch (exception) {
        LogException(
            'QueryWorkspaces failed',
            exception
        );
        throw exception;
    }
}

/**
 * Helper to list workspaces matching searchText.
 * @param searchText Text entered by the end-user. May be null or empty in which case all results are to be listed.
 * @param pageNumber The page number to request.
 * @param orderBy The property used to order results. The default is by LastActivityTime.
 * @param ascending True if results are returned in ascending order. The default is false (descending).
 */
export async function ListWorkspacesWithTextSearchFilter(
    searchText: string,
    pageNumber: number,
    orderBy: QueryableEntityProperties = 'LastActivityTime',
    ascending: boolean = false)
    : Promise<PaginatedEntitiesResponse> {

    const filters = searchText
        ?
        [
            [
                // display name matches filter expression.
                CreateTextSearchFilter('DisplayName', searchText),
            ]
        ]
        : [];

    const ordering = [{
        ascending: ascending,
        orderedBy: orderBy,
    }];

    // demonstrates how to apply a secondary ordering to results.
    // results will be ordered by the requested property and then by last activity time desc.
    if (orderBy !== 'LastActivityTime') {
        ordering.push({
            ascending: false,
            orderedBy: 'LastActivityTime',
        });
    }

    try {
        return await QueryWorkspaces({
            itemsPerPage: PAGE_SIZE,
            pageNumber: pageNumber,
            ordering: ordering,
            filters: filters,
        });
    }
    catch (e) {
        console.error('An error occurred listing business entities.');
        console.error(e);

        return {
            itemsPerPage: PAGE_SIZE,
            itemsReturned: 0,
            pageData: [],
            pageNumber: 1,
            responseTimestamp: new Date().toISOString(),
            totalItems: 0,
            totalPages: 0,
            filters: filters,
            ordering: ordering,
        }
    }
}

/**
 * updates the workspace name.  
 *
 * @param workspaceId id of the workspace
 * @param newWorkspaceName new name for the workspace.
 * @param newEngagementCode new engagement code for the workspace.
 *  
 * @result updates the workspace name.
 */

export async function UpdateWorkspaceDetails(
    workspaceId: string,
    newWorkspaceName: string,
    newEngagementCode?: string
): Promise<void> {
    const url = `/api/Entities/${workspaceId}`;
    const requestBody: Operation[] = [
        {
            value: newWorkspaceName,
            path: 'displayname',
            op: 'replace'
        },
        {
            value: newEngagementCode,
            path: 'engagementcode',
            op: 'replace'
        }
    ]
    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Patch,
                body: JSON.stringify(requestBody),
            },
            false,
            true,
            // for nocache
            true,
            // for jsonpatchheaders
            true
        );
        if (!res.ok) {
            console.error('failure updating workspace name');
            console.error(res);
        }
    } catch (e) {
        console.error('Error caught attempting to update workspace name');
    }
}

/**
 * Deletes a workspace. 
 *
 * @param workspaceId   id of the workspace
 *  
 * @resul workspace is deleted
 */

export async function DeleteWorkspace(
    workspaceId: string,
    onComplete?: (success: boolean) => void,
): Promise<void> {
    LogMessage(
        'DeleteWorkspace',
        SeverityLevel.Information,
        {
            workspaceId
        }
    );
    
    const url = `/api/Entities/${workspaceId}`;
    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Delete,
            },
            true,
            true,
        );
        if (!res.ok) {
            LogException(
                'DeleteWorkspace failed',
                new Error(res.statusText),
                {
                    workspaceId
                }
            );
        }
        onComplete && onComplete(true);
    } catch (exception) {
        LogException(
            'DeleteWorkspace failed',
            exception,
            {
                workspaceId
            }
        );

        console.error(`Exception deleting business ${workspaceId}`);
        console.error(exception);
        onComplete && onComplete(false);
    }
}


/**
 * Just get workspace details for a single entity based on id
 * 
 * @param id.  id of the workspace to retrieve
 * @throws Error If server returns error or if error sending request.
 */

export async function GetWorkspaceDetails(id: string): Promise<EntityDetailResponse | undefined> {
    if (!id) throw new Error(`id is required.`)

    LogMessage(
        'GetWorkspaceDetails',
        SeverityLevel.Information,
        {
            id
        }
    );

    const url = `/api/Entities/${id}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true,
        );

        if (res.ok) {
            return res.json();
        } else {
            LogException(
                'GetWorkspaceDetails failed',
                new Error(res.statusText),
                {
                    id
                }
            );

            return undefined;
        }
    } catch (fetchException) {
        LogException(
            'GetWorkspaceDetails failed',
            fetchException,
            {
                id
            }
        );

        console.error(`Exception retrieving entity details ${id}`);
        console.error(fetchException);

        return undefined;
    }
}

/**
 * Just get limited set of safe workspace details for a single entity based on id.
 * Primarily added for use in borrower flow.
 * 
 * @param id.  id of the workspace to retrieve
 * @throws Error If server returns error or if error sending request.
 */

export async function GetWorkspaceSafeDetails(id: string): Promise<EntitySafeDetailResponse | undefined> {
    if (!id) throw new Error(`id is required.`)

    LogMessage(
        'GetWorkspaceSafeDetails',
        SeverityLevel.Information,
        {
            id
        }
    );

    const url = `/api/Entities/${id}/safedetails`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true,
        );

        if (res.ok) {
            return res.json();
        } else {
            LogException(
                'GetWorkspaceSafeDetails failed',
                new Error(res.statusText),
                {
                    id
                }
            );

            return undefined;
        }
    } catch (fetchException) {
        LogException(
            'GetWorkspaceSafeDetails failed',
            fetchException,
            {
                id
            }
        );

        console.error(`Exception retrieving entity safe details ${id}`);
        console.error(fetchException);

        return undefined;
    }
}

export function WorkspaceActionEnabled(workspace: WorkspaceWithMeta, action: UserCapability): boolean {
    /*
    if (action === 'CanManageWorkspaceUsers') {
        // TODO:  Remove this and use actual capability as returned by controller
        return false;
    }
    */

    if (!workspace.workspaceUserCapabilities) {
        return false;
    }
    return !!workspace.workspaceUserCapabilities.find(cap => cap === action);
}

export async function UpdateWorkspaceUsers(workspaceId: string, userIds: string[]): Promise<void> {
    LogMessage(
        'UpdateWorkspaceUsers',
        SeverityLevel.Information,
        {
            workspaceId,
            userIds
        }
    );

    try {
        const url = `/api/Entities/${workspaceId}/users`;
        const requestBody: UpdateEntityUsersRequest = {
            resourceUsers: userIds.map(id => { return { principalId: id } })
        }
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Put,
                body: JSON.stringify(requestBody),
            },
            true,
            true,
        );
        if (!res.ok) {
            throw new Error(`UpdateWorkspaceUsers, result from api call failed ${res.status} ${res.statusText}`);
        }

    } catch (exception) {
        LogException(
            'UpdateWorkspaceUsers failed',
            exception,
            {
                workspaceId,
                userIds
            }
        );

        console.error(`UpdateWorkspaceUsers failed, workspace id: ${workspaceId}`);
        console.error(exception);

        throw exception;
    }
}

export async function RequestWorkspaceAccess(workspaceId: string): Promise<EntityAccessResponse> {
    LogMessage(
        'RequestWorkspaceAccess',
        SeverityLevel.Information,
        {
            workspaceId,
        }
    );

    try {
        const url = `/api/Entities/${workspaceId}/requestaccess`;
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
            },
            true,
            true,
        );
        if (!res.ok) {
            throw new Error(`RequestWorkspaceAccess, result from api call failed ${res.status} ${res.statusText}`);
        }

        return await res.json();
    } catch (exception) {
        LogException(
            'RequestWorkspaceAccess failed',
            exception,
            {
                workspaceId,
            }
        );

        console.error(`RequestWorkspaceAccess failed, workspace id: ${workspaceId}`);
        console.error(exception);

        throw exception;
    }
}
