import * as React from 'react';

import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';

import { AccountingPackage } from '../../Models/Api/AccountingPackages';
import { CreatedSubmissionRequest } from '../../Models/Api/strongbox.financialportal';
import { ConnectionRequestDescriptor } from '../../Models/Api/strongbox.models';
import { CreateSubmissionRequest } from '../../Services/SubmissionRequestsService';
import { CreateSubmission } from '../../Services/SubmissionService';
import { getDelegatedToken } from '../../Utils/LinkUtils';

import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { SelectAccountingSystem } from '../SelectAccountingSystem/SelectAccountingSystem';
import { AccountingPkgPresentation } from '../SelectAccountingSystem/AccountingPkgPresentation';
import { ShowLink } from '../ShowLink/ShowLink';

import { EventCategory, MetricsService } from '../../Utils/Metrics';

import { IDelegatedAccessToken } from '@finagraph/strongbox-nexus-client';
import {
    LinkerModal,
    StrongboxConnectionRequest,
    Theme as StrongboxTheme
} from '@finagraph/strongbox-finconnect-react';

import { LinkMode } from '../../Store/ImportFinancials';

import { ErrorState } from '../ErrorBanner/ErrorBanner';

import { LogException, LogMessage, SeverityLevel } from '../../Utils/Logging';

type Props = {
    tenantSelectPkgPrompt: string;
    workspaceId: string;
    workspaceName: string;
    partnerName: string;
    options?: PortalCreateSubmissionRequestOptions;
    strongboxUrl: string;
    strongboxModalThemes: StrongboxTheme | undefined;
    linkMode: LinkMode;
    onComplete?: () => void;
    onAccountingSystemConnected?: (
        workspaceId: string,
        workspaceName: string,
        cxnRequest: StrongboxConnectionRequest,
        apiRequestParameters?: ConnectionRequestDescriptor
    ) => void;
    externallyDisabled?: boolean;
};

type ShareableLinkInfo = {
    creating: boolean;
    url?: string;
    expiration?: string;
    error?: ErrorState;
}

type DirectModeInfo = {
    fetching: boolean;
    accessToken: IDelegatedAccessToken | undefined;
    error?: ErrorState;
}

type LenderLinkInfo = {
    working: boolean;
    error?: ErrorState;
    cxnInfo?: StrongboxConnectionRequest;
}

export const LenderLinkModal: React.FC<Props> = (props): React.ReactElement => {
    const [sharedLinkInfo, setSharedLinkInfo] = React.useState<ShareableLinkInfo>({
        creating: false,
        url: undefined,
        expiration: undefined,
    });

    const {
        linkMode,
        workspaceId,
        options,
        externallyDisabled,
    } = props;

    React.useEffect(() => {
        if (linkMode === LinkMode.shareable) {
            setSharedLinkInfo({
                creating: true,
            });

            LogMessage(
                `Creating submission request for shareable link for workspace ${workspaceId}`,
                SeverityLevel.Information,
                {
                    workspaceId,
                    ...options
                }
            );

            CreateSubmissionRequest(workspaceId, options)
                .then((sr: CreatedSubmissionRequest) => {
                    setSharedLinkInfo({
                        creating: false,
                        url: sr.shareableLink!.url,
                        expiration: sr.shareableLink!.expirationTime,
                    });
                })
                .catch(reason => {
                    LogException(
                        `Failed to create submission request for shareable link for workspace ${workspaceId}`,
                        reason,
                        {
                            workspaceId,
                            ...options
                        }
                    );

                    console.error('Failed creating submission request for the workspace');
                    console.error(reason);

                    setSharedLinkInfo({
                        creating: false,
                        url: undefined,
                        error: {
                            summaryMessage: 'There\'s been an error creating the request for linking with your accounting package. Please try again in a few moments.',
                            extraInformation: reason.message,
                        }
                    });
                });
        }
    }, [linkMode, workspaceId, options]);

    const [directModeInfo, setDirectModeInfo] = React.useState<DirectModeInfo>({
        accessToken: undefined,
        fetching: false,
    });

    const getTokenErrorMessage = 'An error has occurred initializing this operation. Please try again in a few moments';

    React.useEffect(() => {
        if ((linkMode === LinkMode.direct) || (linkMode === LinkMode.excel)) {
            LogMessage(
                `Getting delegated token for direct link for workspace ${workspaceId}`,
                SeverityLevel.Information,
                {
                    workspaceId
                }
            );

            getDelegatedToken(workspaceId)
                .then(token => {
                    setDirectModeInfo({
                        fetching: false,
                        accessToken: token,
                    });
                })
                .catch(reason => {
                    LogException(
                        `Failed getting delegated access token for workspace ${workspaceId}`,
                        reason,
                        {
                            workspaceId
                        }
                    );

                    console.error('Error retrieving delegated access token');
                    console.error(reason);

                    setDirectModeInfo({
                        fetching: false,
                        accessToken: undefined,
                        error: {
                            summaryMessage: getTokenErrorMessage,
                            extraInformation: reason.message,
                        }
                    });
                });

            setDirectModeInfo({
                accessToken: undefined,
                fetching: true,
            });
        }
    }, [linkMode, workspaceId]);

    React.useEffect(() => {
        if (linkMode === LinkMode.excel) {
            if ((!!directModeInfo) && (!directModeInfo.fetching)) {
                linkAccountingPackage({
                    featureName: AccountingPackage.FileUpload,
                    descriptor: "not used",
                    onPkgSelected: (pkg) => { },
                })
            }
        }
        // These are the dependencies I actually want
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [linkMode, directModeInfo])

    const [connectingInfo, setConnectingInfo] = React.useState<LenderLinkInfo | undefined>(undefined);

    const startImport = (accountingPackage: AccountingPackage, submissionId: string): void => {
        if (!directModeInfo.accessToken) {
            return;
        }

        const cxnInfo: StrongboxConnectionRequest = {
            accountingPackage,
            delegatedAccessToken: directModeInfo.accessToken,
            strongboxUri: props.strongboxUrl,
            orgId: workspaceId,
            existingConnectionId: undefined,
            submissionId,
            sourceFlow: 'lender',
        }

        setConnectingInfo({
            ...connectingInfo,
            working: false,
            cxnInfo,
        });
    }

    const linkAccountingPackage = async (selectedPkg: AccountingPkgPresentation): Promise<void> => {
        setConnectingInfo({
            ...connectingInfo,
            working: true,
        })

        let submissionId;

        try {
            const subRequest = await CreateSubmissionRequest(workspaceId, options);

            submissionId = await CreateSubmission(workspaceId, subRequest && subRequest.id);

            if (!submissionId) {
                setConnectingInfo({
                    ...connectingInfo,
                    error: {
                        summaryMessage: 'An error has occurred linking to the customers accounting system. Please try again later',
                    },
                    working: false,
                })

                return;
            }
        } catch (exception) {
            setConnectingInfo({
                ...connectingInfo,
                error: {
                    summaryMessage: 'An error has occurred linking to the customers accounting system. Please try again later',
                    extraInformation: exception.toString(),
                },
                working: false,
            })

            return;
        }

        MetricsService.tryTrackEvent(EventCategory.Import, 'BeginWorkspaceList', { package: selectedPkg.featureName });

        // Really it shouldn't be possible to get here and have pkg.featureName not actually be an accounting package descriptor.
        // AccountingPkgPresentation supports feature name being an accounting package or a string for demo types.  Demo
        // accounting package systems shouldn't be using this method, rather they should just be using a method that does nothing.

        const accountingPkg = selectedPkg.featureName as AccountingPackage;
        if (!accountingPkg) {
            return;
        }

        startImport(accountingPkg, submissionId);
    }

    const onConnected = (cxnRequest: StrongboxConnectionRequest, apiRequestParameters?: ConnectionRequestDescriptor): void => {
        setConnectingInfo(undefined);
        props.onAccountingSystemConnected &&
            props.onAccountingSystemConnected(workspaceId, props.workspaceName, cxnRequest, apiRequestParameters);
    }

    const onLinkCompleted = (success: boolean): void => {
        if (linkMode === LinkMode.excel) {
            // This means the user canceled out of the link dialog and 
            // we should just exit the lenderLinkModal.  onComplete
            // will toggle

            props.onComplete && props.onComplete();
        } 
        setConnectingInfo(undefined);
    }

    const RenderLinkDialog = (): React.ReactElement => {
        if (!(connectingInfo && connectingInfo.cxnInfo)) {
            return (<></>);
        }

        return (
            <LinkerModal
                cxnRequest={connectingInfo.cxnInfo}
                theme={props.strongboxModalThemes}
                onCompleted={onLinkCompleted}
                onConnected={onConnected}
                checkAuthorizationStatus={false}
            />
        );
    }

    const regularState = (): React.ReactElement => {
        return (
            <Container>
                {
                    (linkMode === LinkMode.shareable) && (!!sharedLinkInfo.url) && (
                        <Row>
                            <Col xs={1} />
                            <Col>
                                <ShowLink
                                    copyLinkOnOpen={true}
                                    sharedLink={sharedLinkInfo.url || ''}
                                    workspaceName={props.workspaceName}
                                    linkExpiration={sharedLinkInfo.expiration}
                                />
                            </Col>
                            <Col xs={1} />
                        </Row>
                    )
                }
                {
                    (linkMode === LinkMode.direct) && (!!directModeInfo.accessToken) && (
                        <>
                            <Row>
                                <Col>
                                    <p>{props.tenantSelectPkgPrompt}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <SelectAccountingSystem
                                        buttonsDisabled={false}
                                        onLink={linkAccountingPackage}
                                        tabIndexBase={1}
                                    />
                                </Col>
                            </Row>
                        </>
                    )
                }
                {
                    (linkMode === LinkMode.excel) && (
                        <div className={'control-region-lender'}>
                            <Row className={'hex-button-action-container'}>
                                <Col className={`hex-button-col centered-col`}>
                                    <Button
                                        onClick={() => { }}
                                        disabled={true}
                                        className={`small-hex-button hex-button-no-click`}
                                        tabIndex={0}
                                        autoFocus={false}
                                        style={{
                                            backgroundColor: 'unset',
                                        }}
                                    >
                                        <span
                                            className={`icon-container small-icon-container connect-excel-image`}
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )
                }
                {
                    (<></>)
                }
            </Container>
        );
    }

    const errorMsgState = (errorMsg: ErrorState): React.ReactElement => {
        return (
            <Container>
                <Row>
                    <Col>
                        <p className={'error-text'}>{errorMsg.summaryMessage}</p>
                    </Col>
                </Row>
                {
                    errorMsg.extraInformation && (
                        <Row>
                            <Col>
                                <p>{errorMsg.extraInformation}</p>
                            </Col>
                        </Row>
                    )
                }
            </Container>
        );
    }

    const disabledState =
        (sharedLinkInfo && !!sharedLinkInfo.creating) ||
        (directModeInfo && !!directModeInfo.fetching) ||
        (connectingInfo && !!connectingInfo.working) || 
        (externallyDisabled === true);

    const errorMsg =
        (sharedLinkInfo && sharedLinkInfo.error) ||
        (directModeInfo && directModeInfo.error) ||
        (connectingInfo && connectingInfo.error);

    const modalTitle = linkMode === LinkMode.shareable ? 'Secure Url Information' : 'Link Customer Financial Information';

    if (connectingInfo && connectingInfo.cxnInfo) {
        return RenderLinkDialog();
    } else {
        return (
            <Modal
                className={`flight-modal`}
                isOpen={true}
                backdrop={'static'}
                toggle={() => props.onComplete && props.onComplete()}
            >
                <ModalHeader toggle={props.onComplete}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <LoadingIndicator active={disabledState} centerIndicator={true} />
                    {!errorMsg && regularState()}
                    {errorMsg && errorMsgState(errorMsg)}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={disabledState}
                        onClick={() => props.onComplete && props.onComplete()}
                    >
                        Exit
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
