import * as React from 'react';

import {
    Container,
} from 'reactstrap';

type Props = {
    children?: React.ReactNode;
};

export const MessageBanner: React.FC<Props> = (props): React.ReactElement => {
    return (
        <Container
            className={`message-banner control-region`}
            fluid
        >
            {props.children}
        </Container>
    );
}
